import type { IconType } from '@innovamat/glimmer-icons';

import type { MPTag } from '../components/math-practices';
import type { Dimensions } from './Dimension';
import type { AllMaterials } from './Materials';
import type { MediaType } from './Media';
import type { TableContentType, TableSmartguides } from './Table';

export enum GuideType {
  Laboratory = '1',
  Adventures = '2',
  US = '3',
  FourInOne = '4',
}

export enum PartType {
  BeforeStart = 0,
  Introduction = 1,
  Development = 2,
  Conclusion = 3,
  Video = 4,
}

export const SmartGuideElementType = {
  ampliation: 'Ampliacin',
  management: 'ConsejoDeGestin',
  signedTip: 'Signedtip',
  solution: 'Solucin',
  support: 'Apoyo',
  tip: 'Tip',
  whatToObserve: 'QuePodemosObservar',
} as const;

export type ContentBlockTag = {
  textKey: string;
  isEnabled: boolean;
  isFromPrepr?: boolean; //temporal para que los de contenido sepan de donde viene este standard
};

export type DimensionTag = {
  textKey: string;
  isEnabled: boolean;
  dimension: Dimensions;
};

export type Evaluable = {
  isEvaluable: boolean;
  tooltipText: string | null;
  dimension: Dimensions;
  linkTo: string;
};

export type SmartGuideBase = {
  type: 'smartGuide';
  id: string;
  title: string;
  subtitle: string;
  sessionCode: string | null;
  parts: (Part & PartVideo)[];
  summary: Summary;
};

type SummaryContentBlock = {
  titleKey: string;
  text: string;
};

export type StandardTag = {
  text: string;
  tooltipKey: string;
  isFromPrepr?: boolean; //temporal para que los de contenido sepan de donde viene este standard
};

export type Summary = {
  inThisTaller: SummaryContentBlock;
  withIntentionOf: SummaryContentBlock;
  vocabularyKey: SummaryContentBlock;
  dimensionTags: DimensionTag[];
  materials: AllMaterials;
  assessables: string[];
  newStandardContent: StandardTag[] | ContentBlockTag[];
  newMathPractices: MPTag[];
};

export type SmartGuideTaller = SmartGuideBase & {
  __typename: 'SmartGuideTaller';
};

export type SmartGuidePrimaria = SmartGuideBase & {
  __typename: 'SmartGuidePrimaria';
  guideType: GuideType;
};

export type SmartGuideSecundaria = SmartGuideBase & {
  __typename: 'SmartGuideSecundaria';
};

export type PreprModel =
  | 'EarlyYearsSpace'
  | 'EarlyYearsClassroomLife'
  | 'EarlyYearsAllSpace'
  | 'SecondaryEducationGuide'
  | 'SmartGuideTaller'
  | 'SmartGuidePrimaria'
  | 'SmartGuideSecundaria'
  | 'SmartGuideOpen';

export type SmartGuideType =
  | SmartGuidePrimaria
  | SmartGuideTaller
  | SmartGuideSecundaria;

export type BeforeStartContent = {
  content: string;
  media: MediaType[] | null;
};

export type ContentBlock = {
  title: string;
  text: string;
  icon: IconType;
};

export type Part = {
  id: string;
  title: string;
  partNumber: PartType;
  oneLineSummary?: string | null;
  evaluable?: Evaluable;
  contentBlocks?: ContentBlock[];
  beforeStartContent?: BeforeStartContent;
  steps?: StepType[];
  activityNumber?: number | null;
  keyMoment?: boolean;
  partIcon?: IconType | undefined;
  hasSupport?: boolean;
  hasExtension?: boolean;
  hasSupportLink?: string;
  hasExtensionLink?: string;
};

export type PartVideo = {
  _id: string;
  chapterName: string;
  chapterDuration: number;
  title: string;
  should_translate: boolean;
  videoChapterContent: ChapterContent[];
};

export type ChapterContent = {
  key: string;
  icon: string;
  content?: string;
};

export type StepType =
  | Agrupation
  | PasoNumerado
  | Tip
  | SignedTip
  | ManagementComment
  | WhatToObserve
  | Solution
  | PartDivider
  | TextItem
  | StepMedia
  | TableSmartguides
  | PageBreak;

export type GroupedSteps = {
  __typename: string;
  steps: StepType[];
};

export type Content = Exclude<StepType, PasoNumerado>;

export type Tip = Support | Extension;

export type ManagementComment = Management | Formation;

export type TextBlock = {
  text: string;
};

export type MediaBlock = {
  media: MediaType[] | null;
};

export type TablesBlock = {
  tables?: TableContentType[];
};

export type PageBreak = {
  __typename: 'PageBreak';
};

export type StepMedia = MediaBlock & {
  __typename: 'ZzImagen';
};

export type Agrupation = TextBlock & {
  __typename: 'Agrupacin';
  part: PartType;
};

export type PartDivider = TextBlock & {
  __typename: 'Subactividad';
  part: PartType;
};

export type WhatToObserve = {
  __typename: 'QuePodemosObservar';
  items: WhatToObserveItem[];
};

export type WhatToObserveItem = TextBlock &
  MediaBlock & {
    content: string;
    dimension: Dimensions | undefined;
  };

export type PasoNumerado = {
  __typename: 'PasoNumerado';
  stepNumber: number | null;
  evaluable: Evaluable;
  content: Content[];
};

export type Management = TextBlock &
  MediaBlock &
  TablesBlock & {
    __typename: 'ConsejoDeGestin';
  };

export type TextItem = {
  __typename: 'Text';
  text: string | JSX.Element;
};

export type TipBlock = TextBlock & MediaBlock & TablesBlock;

export type Support = TipBlock & {
  __typename: 'Apoyo';
};

export type Extension = TipBlock & {
  __typename: 'Ampliacin';
};

export type Formation = TextBlock &
  MediaBlock &
  TablesBlock & {
    __typename: 'Formacin';
  };

export type Solution = TextBlock &
  MediaBlock &
  TablesBlock & {
    __typename: 'Solucin';
    part: PartType;
  };

export type SignedTip = TipBlock & {
  __typename: 'Signedtip';
  tip_type: string;
  description: string;
};
