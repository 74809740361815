import {
  InnerHtml,
  Modal,
  ResourceElement,
} from '@innovamat/glimmer-components';

import { useSaveGoogleDrive } from '@innovamat/ga-features';
import { snack } from '@innovamat/glimmer-components';
import { useResource } from '@innovamat/glow-api-client';
import { saveFile } from '@innovamat/radiance-utils';
import { Generic, Pdf } from '@innovamat/resource-viewer';
import { detectIsInWebview, useWebviewBridge } from '@innovamat/webview-bridge';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnvVariables } from '../../../env-variables-manager';
import { ContentResource } from '../../types';

type AssessableResourcesElementProps = {
  resource: ContentResource;
  sessionId: string;
  sessionName: string | undefined;
  classroomId: string;
  setEventData: (event: string, data: Record<string, unknown>) => void;
  academicYearId?: string;
  isStudent: boolean;
  language: string;
  region: string | undefined;
};

export default function AssessableResourcesElement({
  resource,
  sessionId,
  sessionName,
  classroomId,
  setEventData,
  academicYearId,
  isStudent,
  language,
  region,
}: AssessableResourcesElementProps): JSX.Element {
  const { GOOGLE_CLIENT_ID, GOOGLE_SECRET } = useEnvVariables();
  const { t } = useTranslation();

  const [file, setFile] = useState();
  const [googleDriveUrl, setGoogleDriveUrl] = useState<string | null>();

  const { resource: data } = useResource({
    id: resource.id,
    type: resource.type,
    classroomId,
    language,
    academicYearId,
    isStudent,
    region,
  });

  const { isInWebview } = useWebviewBridge();

  const myData =
    resource.type === 'generic' ? (data as Generic) : (data as Pdf);
  const path = myData?.path || '';
  const elements = path.split('.');
  const extension = elements[elements.length - 1];
  const fileName = `${myData?.title}.${extension}`;

  const onDownload = (): void => {
    if (!myData) return;

    setEventData('assessment_resource_download', {
      resource_id: resource.id,
      resource_name: resource.name,
      resource_type: resource.type,
      session_id: sessionId,
      session_name: sessionName,
    });

    saveFile(path, fileName, detectIsInWebview);
  };

  const getFile = async () => {
    const response = await axios.get(path, {
      responseType: 'blob',
    });
    setFile(response.data);
  };

  useEffect(() => {
    if (path && resource.type === 'generic') {
      getFile();
    }
  }, [path, resource]);

  const handleErrorGoogleDrive = (): void => {
    snack.error(t('notification.saveGoogleDrive.error'));
  };

  const handleSuccessGoogleDrive = (url: string): void => {
    setGoogleDriveUrl(url);
  };

  const { signIn, loading: googleDriveLoading } = useSaveGoogleDrive({
    file,
    fileName,
    googleClientId: GOOGLE_CLIENT_ID,
    googleSecret: GOOGLE_SECRET,
    onError: handleErrorGoogleDrive,
    onSuccess: handleSuccessGoogleDrive,
  });

  const handleSaveGoogleDrive = (): void => {
    signIn();
  };

  const onCloseModal = () => {
    setGoogleDriveUrl(null);
  };

  const openGoogleDriveFile = () => {
    googleDriveUrl && window.open(googleDriveUrl, '_blank');
    setEventData('assessment_resource_drive', {
      resource_id: resource.id,
      resource_name: resource.name,
      resource_type: resource.type,
      session_id: sessionId,
      session_name: sessionName,
    });
  };

  const isOpenable = resource.type === 'pdf';

  const handleOpen = (): void => {
    if (!isOpenable || !myData) return;

    setEventData('assessment_resource_click', {
      resource_id: resource.id,
      resource_name: resource.name,
      resource_type: resource.type,
      session_id: sessionId,
      session_name: sessionName,
    });

    window.open(path, '_blank');
  };

  function getIcon(): 'FileXlsIcon' | 'FileStandardIcon' {
    switch (resource.type) {
      case 'generic':
        return 'FileXlsIcon';
      default:
        return 'FileStandardIcon';
    }
  }

  const showGoogleDriveButton = resource.type === 'generic' && file;

  // It is ready to get added in the future if needed
  const showOpenButton = false;

  return (
    <>
      <ResourceElement
        icon={getIcon()}
        isDownloadable
        isOpenable={isOpenable}
        onDownload={onDownload}
        onOpen={!isInWebview ? handleOpen : onDownload}
        onSaveGoogleDrive={handleSaveGoogleDrive}
        showOpenButton={isOpenable && showOpenButton}
        isDisabled={!myData || googleDriveLoading}
        showGoogleDriveButton={showGoogleDriveButton}
      >
        <InnerHtml text={resource.name} />
      </ResourceElement>
      <Modal
        isOpen={!!googleDriveUrl}
        onClose={onCloseModal}
        title={t('modal.googleDrive.title').toString()}
        closeOnEsc
        closeOnClickOutside
        modalWidth={700}
        buttons={[
          {
            children: t('modal.googleDrive.button.open'),
            variant: 'accent',
            onClick: openGoogleDriveFile,
          },
          {
            children: t('common.cancel'),
            variant: 'tertiary',
            onClick: onCloseModal,
          },
        ]}
      >
        <>{t('modal.googleDrive.content.description')}</>
      </Modal>
    </>
  );
}
