import { getAcceptLanguage } from '@innovamat/localization';
import { Resource, resourceMapper } from '@innovamat/resource-viewer';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useRef } from 'react';
import { ResourceDocument, ResourceQuery } from '../../generated/types';
import { useGlowApiClient } from '../../graphql-provider';
import { useCompletedResources } from '../use-completed-resources';

type Props = {
  id: string;
  type: string;
  classroomId: string | undefined;
  language: string;
  region: string | undefined;
  academicYearId?: string;
  isStudent: boolean;
  isLoggedIn?: boolean;
  standard?: string;
  courseOrder?: number;
};

export function useResource({
  id,
  type,
  classroomId,
  language,
  academicYearId,
  isStudent,
  isLoggedIn = true,
  standard,
  courseOrder,
  region,
}: Props) {
  const completedResources = useCompletedResources({
    classroomId,
    academicYearId,
    isStudent,
  });

  const prevCompletedResources = useRef(completedResources);

  const variables = {
    id,
    classroomId: classroomId || 'self',
    type,
    courseOrder,
    standard,
  };

  const { graphqlClient, getAuthToken } = useGlowApiClient();

  const getIsEnabled = () => {
    if (!region || !language) return false;
    return isLoggedIn ? !!completedResources && !!id : !!id;
  };

  const resourceQuery = useQuery<ResourceQuery>({
    queryKey: ['Resource', variables],
    queryFn: () => {
      const headers = {
        'accept-language': getAcceptLanguage(region, language) ?? '*',
        authorization: getAuthToken() ?? '',
      };

      return graphqlClient.request(ResourceDocument, variables, headers);
    },
    enabled: getIsEnabled(),
    retry: false,
  });

  useEffect(() => {
    if (prevCompletedResources.current !== completedResources) {
      resourceQuery.refetch();
      prevCompletedResources.current = completedResources;
    }
  }, [isStudent]);

  const resource = useMemo(() => {
    if (!resourceQuery.data) return undefined;
    return resourceQuery.data.resource;
  }, [resourceQuery.data]);

  const mappedResource = useMemo(() => {
    if (!resource) return undefined;
    return resourceMapper({
      locale: language,
      response: resource as any,
      completedResources,
      isBloqued: false,
      isStudent,
    });
  }, [resource, language, completedResources, isStudent]);

  const isUnauthorized = (resourceQuery?.error as any)?.response?.errors?.some(
    (error: any) => {
      return error?.extensions?.code === 'UNAUTHENTICATED';
    }
  );

  return {
    status: resourceQuery.status,
    error: resourceQuery.error,
    isUnauthorized: isUnauthorized,
    resource: mappedResource as Resource,
  };
}
