import { TestsAndResultsTableSkeleton } from './components/test-and-results-table/tests-and-results-table.skeleton';
import { TypesOfTestsAnnouncementPanelSkeleton } from './components/types-of-tests-announcement-panel/types-of-tests-announcement-panel.skeleton';

const TestsAndResultsSkeleton = (): JSX.Element => {
  return (
    <>
      <TypesOfTestsAnnouncementPanelSkeleton />
      <TestsAndResultsTableSkeleton />
    </>
  );
};

export { TestsAndResultsSkeleton };
