import { useUser } from '@innovamat/ga-features';
import React from 'react';
import { redirectWithReferral } from '../../utils/redirect-with-referral';

type Props = {
  children: React.ReactNode;
};

function ProtectedRoute({ children }: Props) {
  const { isLoggedIn } = useUser();

  if (!isLoggedIn) {
    redirectWithReferral();
    return null;
  }

  return children;
}

export { ProtectedRoute };
