import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import { ContentBlockTag } from '../../types/SmartGuide';
import { Stage } from '../../types/Stage';
import { SimpleBlock } from '../SimpleBlock';
import { Tag } from '../Tag';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media print {
    gap: 4px;
  }
`;

type Props = {
  tags: ContentBlockTag[];
  hasAssessables: boolean;
};

export function ContentBlockTags({ tags, hasAssessables }: Props) {
  const { t } = useTranslation();
  const { stage } = useSmartGuides();

  const areAllTagsDisabled = tags.every((tag) => !tag.isEnabled);

  if (areAllTagsDisabled) {
    return null;
  }

  const title = t('digitalguides.blocks');

  return (
    <SimpleBlock title={title}>
      <Container>
        {tags.map((tag, index) => {
          if (hasAssessables && stage === Stage.Primary && !tag.isEnabled) {
            return null;
          }

          return (
            <Tag
              key={`${tag.textKey}-${index}`}
              text={t(tag.textKey)}
              isEnabled={tag.isEnabled}
            />
          );
        })}
      </Container>
    </SimpleBlock>
  );
}
