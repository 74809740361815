import styled from '@emotion/styled';

import { useSmartGuides } from '../../context/SmartGuidesProvider';
import { PrintableBody2 } from '../../printable-styles/printable-typography';
import type { MediaType } from '../../types/Media';
import type { TableContentType } from '../../types/Table';
import { Accordion } from '../Accordion';
import { Media } from '../Media';
import { PreprInnerHtml } from '../PreprInnerHtml';
import { Table } from '../Table';

type BaseTipProps = {
  children: string;
  media?: MediaType[] | null;
  isPrintable?: boolean;
  tables?: TableContentType[];
};

type TypedTipProps = BaseTipProps & {
  type: 'ampliation' | 'support';
  summary?: undefined;
};

type NonTypedTipProps = BaseTipProps & {
  type?: undefined;
  summary: string;
};

type Props = TypedTipProps | NonTypedTipProps;

const Text = styled(PrintableBody2)`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
`;

const summaryMapper: Record<TypedTipProps['type'], string> = {
  ampliation: 'digitalguides.ampliation',
  support: 'digitalguides.support',
};

export function Tip({
  type,
  children,
  media,
  isPrintable,
  tables,
  summary,
}: Props): JSX.Element {
  const { t } = useSmartGuides();
  const hasTable = tables?.length && tables.length > 0;

  return (
    <Accordion
      summary={type ? t(summaryMapper[type]) : summary}
      isPrintable={isPrintable}
    >
      <Text>
        <PreprInnerHtml text={children} />
      </Text>

      {media && <Media media={media} />}
      {hasTable && <Table tables={tables} />}
    </Accordion>
  );
}
