import styled from '@emotion/styled';
import { IconButton, Tooltip, Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

type Props = {
  onCloseFullScreen: () => void;
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled(Typography.Body2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

export function CloseButtonFullScreen({
  onCloseFullScreen,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <TitleWrapper>
      <IconButton icon="ShrinkIcon" onClick={onCloseFullScreen} />
      <Title>{t('reports.closeFullWindow')}</Title>
    </TitleWrapper>
  );
}
