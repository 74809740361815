import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { ProcessedSitemapTree } from '@innovamat/ga-features';
import {
  CourseNavigator,
  LateralMenu,
  useNavigation,
} from '@innovamat/ga-features';
import { getMotion } from '@innovamat/glimmer-components';
import { useState } from 'react';
import { useParams } from 'react-router-domv6';
import { useRedirectSitemapNotFound } from '../../hooks/use-redirect-sitemap-not-found';
import { HEADER_HEIGHT } from './header';

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const MenuFrame = styled.div<{
  isCourseNavigatorOpen: boolean;
  isHidden: boolean;
  hideLateralMenuWithTransition: boolean;
}>`
  padding-top: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.5rem;
  max-width: 17rem;
  min-width: ${({ isHidden }) => (isHidden ? '0' : '17rem')};
  position: sticky;
  top: ${HEADER_HEIGHT}rem;
  height: calc(100vh - ${HEADER_HEIGHT}rem);
  margin-bottom: -2.625rem;
  overflow-y: ${({ isCourseNavigatorOpen }) =>
    isCourseNavigatorOpen ? 'visible' : 'auto'};
  z-index: ${({ isCourseNavigatorOpen }) =>
    isCourseNavigatorOpen ? '20' : '0'};

  width: ${({ isHidden }) => (isHidden ? '0' : '17rem')};
  transform: ${({ isHidden }) =>
    isHidden ? 'translateX(-100%)' : 'translateX(0)'};
  opacity: ${({ isHidden }) => (isHidden ? '0' : '1')};

  ${({ theme, hideLateralMenuWithTransition }) => {
    if (!hideLateralMenuWithTransition) return;

    const fullScreenTransition = getMotion(
      theme.tokens.motion['fullscreen-entry-exit'].value
    );
    return css`
      transition: transform ${fullScreenTransition},
        opacity ${fullScreenTransition}, width ${fullScreenTransition};
    `;
  }}

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const PageFrame = styled.div`
  flex: 1;
  padding-top: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 2rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 1rem;
  }
`;

const Gap = styled.div`
  padding-bottom: 0.5rem;
`;

type Props = {
  children: JSX.Element;
  className?: string;
  hideLateralMenu?: boolean;
  hideLateralMenuWithTransition?: boolean;
  sitemapTree?: ProcessedSitemapTree;
};

export function MainLayout({
  children,
  className,
  hideLateralMenu = false,
  hideLateralMenuWithTransition = false,
  sitemapTree,
}: Props): JSX.Element {
  useRedirectSitemapNotFound();

  const { courseId } = useParams();
  const { navigateBetweenClassesOrCourses } = useNavigation();

  const [isCourseNavigatorOpen, setIsCourseNavigatorOpen] = useState(false);

  return (
    <Container className={className}>
      <MenuFrame
        isCourseNavigatorOpen={isCourseNavigatorOpen}
        isHidden={hideLateralMenu}
        hideLateralMenuWithTransition={hideLateralMenuWithTransition}
      >
        {courseId && (
          <>
            <CourseNavigator
              courseId={courseId}
              onSelectCourse={navigateBetweenClassesOrCourses}
              onToggle={(isOpen) => setIsCourseNavigatorOpen(isOpen)}
            />
            <Gap />
          </>
        )}
        <LateralMenu sitemapTree={sitemapTree} />
      </MenuFrame>
      <PageFrame className="page-frame">{children}</PageFrame>
    </Container>
  );
}
