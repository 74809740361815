import { useEventLogging } from '@innovamat/event-logging';
import {
  AdminDashboard,
  APP_PATHS,
  useNavigation,
  useUser,
} from '@innovamat/ga-features';
import { useClassroomsQuery } from '@innovamat/glow-api-client';
import { stages } from '@innovamat/radiance-utils';

import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-domv6';

function AdminDashboardWrapper(): JSX.Element {
  const { user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const { setEventData } = useEventLogging();

  const {
    data: classroomsQuery,
    isLoading,
    isError,
  } = useClassroomsQuery(
    { mine: false, orgId: user?.organizationId! },
    { enabled: !!user?.organizationId, staleTime: Infinity }
  );

  const classrooms = classroomsQuery?.classrooms || [];

  const hasStages = useMemo(() => {
    return {
      infantil: classrooms.some((classroom) =>
        stages.isInfantil(classroom?.courseOrder)
      ),
      primaria: classrooms.some((classroom) =>
        stages.isPrimaria(classroom?.courseOrder)
      ),
      secundaria: classrooms.some((classroom) =>
        stages.isSecundaria(classroom?.courseOrder)
      ),
    };
  }, [classrooms]);

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const handleSetParams = (search: string): void => {
    navigate({
      pathname: location.pathname,
      search: search,
    });
  };

  const { navigateInSameNavType } = useNavigation();
  const { GENERAL_PROGRESS_URL } = APP_PATHS;

  const goToClassroom = (classroomId: string): void => {
    navigateInSameNavType(
      `/classrooms/${classroomId}${GENERAL_PROGRESS_URL}/page`
    );
  };

  return (
    <AdminDashboard
      organizationId={user?.organizationId!}
      stagesAreLoading={isLoading}
      stages={hasStages}
      isError={isError}
      searchParams={searchParams}
      onSetSearchParams={handleSetParams}
      setEventData={setEventData}
      goToClassroom={goToClassroom}
    />
  );
}
export { AdminDashboardWrapper };
