import styled from '@emotion/styled';
import { useEventLogging } from '@innovamat/event-logging';
import { useFlag } from '@innovamat/flags';
import { Button } from '@innovamat/glimmer-components';
import { useResource } from '@innovamat/glow-api-client';
import { usePrint } from '@innovamat/hooks';
import { Region } from '@innovamat/localization';
import { Session } from '@innovamat/resource-viewer';
import {
  EarlyYearPrintable,
  SmartGuidePrintable,
} from '@innovamat/smart-guides';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-domv6';
import { useOrganization } from '../../../classrooms-management';
import { useCurrentNavigationValues, useNavigation } from '../../../navigation';
import { useUser } from '../../../user-management';
import { useResourceParams } from '../../hooks';
import useGetPreprResource from '../../hooks/use-get-prepr-resource';

const Wrapper = styled.div`
  padding: 24px;
  width: 100%;
  height: var(--realVh);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 58px;

  @page {
    margin: 12.5mm;
  }
`;

const BreakPage = styled.div`
  page-break-after: always;
`;

const PrintSection = styled.div`
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #e5e5e5;
  padding: 8px;
  width: 280px;
  position: fixed;
  bottom: 10px;
  gap: 4px;
  left: 50%;
  border-radius: 8px;
  transform: translateX(-50%);
  z-index: 20;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const Disclaimer = styled.div<{ show: boolean }>`
  font-size: 12px;
  color: #888;

  max-height: ${({ show }) => (show ? '100px' : '0px')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

type Props = {
  onPrint: () => void;
  loading: boolean;
};

export function PrintFooter({ onPrint, loading }: Props) {
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  return (
    <PrintSection
      onMouseEnter={() => setShowDisclaimer(true)}
      onMouseLeave={() => setShowDisclaimer(false)}
    >
      <>
        <Button fill onClick={onPrint} variant="accent" loading={loading}>
          Imprimir
        </Button>
        <Disclaimer show={showDisclaimer}>
          Recuerda modificar las opciones para los márgenes y la leyenda a la
          hora de imprimir
        </Disclaimer>
      </>
    </PrintSection>
  );
}

function PrintGuide({ id }: { id: string }) {
  const { t, i18n } = useTranslation();
  const { isRole, user, isLoggedIn } = useUser();
  const { currentClassroom } = useCurrentNavigationValues();
  const { organization } = useOrganization();
  const { goToHome } = useNavigation();
  const { setEventData } = useEventLogging();

  const { region: urlRegion, language: urlLanguage } = useResourceParams();

  const academicYearId = organization?.academicYearId!;
  const isStudent = isRole.Student;

  const language = urlLanguage || user?.locale!;
  const region = urlRegion || user?.region!;

  const { value: showDynamicStandards } = useFlag('dynamic-standards', false, {
    identifier: '',
    email: user?.email ?? '',
    custom: {},
  });

  const { resource, isUnauthorized } = useResource({
    id: id,
    type: 'session',
    classroomId: undefined,
    language,
    academicYearId,
    isStudent,
    isLoggedIn,
    standard:
      showDynamicStandards && organization?.standard
        ? organization?.standard
        : undefined,
    courseOrder: currentClassroom?.courseOrder ?? undefined,
    region: region,
  });

  useEffect(() => {
    if (isUnauthorized) {
      goToHome();
    }
  }, [isUnauthorized]);

  const session = resource as Session;

  const { data: preprResource } = useGetPreprResource({
    id: session?.guide?.id!,
    locale: language,
    enabled: !!session,
    session,
    region: region! as Region,
  });

  const i18nTranslations =
    i18n.getDataByLanguage(i18n.language) || i18n.getDataByLanguage('es');

  const { translations } = i18nTranslations as any;

  if (preprResource?.type === 'smartGuide')
    return (
      <>
        <SmartGuidePrintable
          smartGuide={preprResource}
          session={session}
          translations={translations}
          t={t}
          setEventData={setEventData}
          region={region}
        />
        <BreakPage />
      </>
    );

  if (
    preprResource?.type === 'digitalGuide' &&
    preprResource?.preprType === 'earlyYear'
  ) {
    return (
      <>
        <EarlyYearPrintable
          guide={preprResource}
          resource={{ ...session, language: language || 'es' }}
          t={t}
          onEvent={setEventData}
          translations={translations}
        />
        <BreakPage />
      </>
    );
  }

  return null;
}

export function PrintGuides() {
  const { ids } = useParams();
  const { componentRef, onPrint, loading } = usePrint('Smart Guides');

  const idsArray = ids?.split(',');

  return (
    <Wrapper>
      <PrintFooter onPrint={onPrint} loading={loading} />
      <div ref={componentRef}>
        {idsArray?.map((id) => (
          <PrintGuide id={id} />
        ))}
      </div>
    </Wrapper>
  );
}
