import { dates } from '@innovamat/radiance-utils';

const formatDate = (date: string): string => {
  if (!date) {
    return '--/--/----'; // TODO check format for different regions
  }

  return dates.formatDateFromSql(date);
};

export { formatDate };
