import { useTranslation } from 'react-i18next';
import { Params, useParams } from 'react-router-domv6';

import { Grounder, HeadingSection } from '@innovamat/glimmer-components';
import { useTestsAndResultsQuery } from '@innovamat/glow-api-client';

import { useUser } from '../../../user-management';

import type { Report } from './modules/report/report';

import { TestsAndResultsTable } from './components/test-and-results-table/tests-and-results-table';
import { TestsAndResultsEmptyState } from './components/tests-and-results-empty-state/tests-and-results-empty-state';
import { TypesOfTestsAnnouncementPanel } from './components/types-of-tests-announcement-panel/types-of-tests-announcement-panel';
import { TestsAndResultsSkeleton } from './tests-and-results.skeleton';

export type Test = {
  classroomTestRoundId: string;
  testId: string;
  roundName: string;
  status: string;
  password: string;
  startDate: string;
  endDate: string;
  report?: Report | null;
};

export type TestsAndResults = Test[];

const TestsAndResults = (): JSX.Element => {
  const { t } = useTranslation();

  const { classroomId } = useParams<Params>();
  const { user } = useUser();

  const {
    data,
    isLoading: isLoadingTests,
    isError,
  } = useTestsAndResultsQuery(
    {
      classroomId: classroomId ?? '',
      academicYearId: user?.organization?.academicYearId ?? '',
    },
    { enabled: !!classroomId || !!user?.organization?.academicYearId }
  );

  const testsAndResults = data?.testsAndResults ?? [];

  const getViewContent = (): JSX.Element => {
    if (isLoadingTests || !testsAndResults || isError) {
      return <TestsAndResultsSkeleton />;
    }

    if (!testsAndResults.length) {
      return <TestsAndResultsEmptyState />;
    }

    return (
      <>
        <TypesOfTestsAnnouncementPanel
          classroomId={classroomId}
          userId={user?.id}
        />
        <TestsAndResultsTable data={testsAndResults} />
      </>
    );
  };

  return (
    <>
      <HeadingSection title={t('testsAndResultsView.title')} />
      {getViewContent()}

      <Grounder />
    </>
  );
};

export { TestsAndResults };
