import { useTranslation } from 'react-i18next';
import { useFluencyMultiplicationReportQuery } from '@innovamat/glow-api-client';
import { useFluencyDashboard } from './hooks/use-fluency-dashboard';
import LegendFluency from '../../components/legend-fluency';
import TableFluency from '../../components/table-fluency';
import StudentsFluencyTableDropdown from '../../components/students-fluency-table-dropdown';
import ReportsLoader from '../../components/reports-loader';
import { Typography } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { FluencyDashboardSkeleton } from './fluency-dashboard-skeleton';

type Props = {
  classroomId: string;
  allCellsLocked?: boolean;
};

export type FluencyStatusTooltipProp = {
  allGroupsLocked: boolean | undefined;
  noStudentsConnected: boolean | undefined;
};

const GraphicWrapper = styled.div`
  display: flex;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

const MultiplicationTableTitle = styled(Typography.H4)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const FluencyDashboard = ({ classroomId }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { data: fluencyDashboardReport, isLoading } =
    useFluencyMultiplicationReportQuery({
      id: classroomId!,
    });

  const {
    mode,
    selectedStudent,
    handleChange,
    students,
    multiplicationTableTitle,
    operator,
    tableFluencyData,
    showFluencyStatusTooltip,
    allCellsLocked,
    tableUpdateLoading,
  } = useFluencyDashboard(fluencyDashboardReport);

  if (isLoading) return <ReportsLoader />;

  return (
    <>
      {tableFluencyData && (
        <>
          <StudentsFluencyTableDropdown
            students={students}
            onChange={handleChange}
            selected={selectedStudent}
          />
          <MultiplicationTableTitle>
            {t(`${multiplicationTableTitle}`)}
          </MultiplicationTableTitle>
          <GraphicWrapper>
            {tableUpdateLoading ? (
              <FluencyDashboardSkeleton />
            ) : (
              <ContentWrapper>
                <TableFluency
                  operator={operator}
                  tableFluencyData={tableFluencyData}
                  showFluencyStatusTooltip={showFluencyStatusTooltip}
                />
                <LegendFluency allCellsLocked={allCellsLocked} mode={mode} />
              </ContentWrapper>
            )}
          </GraphicWrapper>
        </>
      )}
    </>
  );
};
