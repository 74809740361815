import { useSmartGuides } from '../../context/SmartGuidesProvider';
import type { MediaType } from '../../types/Media';
import type { TableContentType } from '../../types/Table';
import { Tip } from '../Tip';

type Props = {
  type: string;
  children: string;
  isPrintable?: boolean;
  media?: MediaType[] | null;
  tables?: TableContentType[];
};

export function SignedTip({
  type,
  children,
  isPrintable,
  media,
  tables,
}: Props): JSX.Element {
  const { t } = useSmartGuides();

  return (
    <Tip
      isPrintable={isPrintable}
      media={media}
      summary={t(`digitalguides.signedTip.${type}`)}
      tables={tables}
    >
      {children}
    </Tip>
  );
}
