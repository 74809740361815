import { useUser } from '@innovamat/ga-features';
import { useQueryParams } from '@innovamat/hooks';
import { storage } from '@innovamat/radiance-utils';
import { useEffect } from 'react';
import i18n from '../../config/i18n';

export function LocaleManager(): JSX.Element | null {
  const { user } = useUser();
  const language = useQueryParams().get('language');

  useEffect(() => {
    if (user?.locale && !language) {
      i18n.changeLanguage(user.locale);
      storage.language.set(user.locale);
    }
  }, [user?.locale, language]);

  return null;
}
