import {
  HEADER_INDEX,
  LAST_NAME_FIELD,
  NAME_FIELD,
  SIS_ID_FIELD,
} from '../utils/constants';

function clearEmptyRows(array: string[][]): string[][] {
  return array.filter((row) => row.some((column) => column.trim() !== ''));
}

function isCsvValid(csvData: string[][]): boolean {
  try {
    const clearCsvData = clearEmptyRows(csvData);
    const headers = clearCsvData[HEADER_INDEX];
    const nameIndex = headers.indexOf(NAME_FIELD);
    const lastNameIndex = headers.indexOf(LAST_NAME_FIELD);
    const sisIdIndex = headers.indexOf(SIS_ID_FIELD);

    const hasNameColumn = nameIndex !== -1;
    const hasLastNameColumn = lastNameIndex !== -1;
    const hasSisIdColumn = sisIdIndex !== -1;

    if (!hasNameColumn || !hasLastNameColumn || !hasSisIdColumn) {
      return false;
    }

    for (let i = 1; i < clearCsvData.length; i++) {
      const sisId = clearCsvData[i][sisIdIndex].trim();
      if (!sisId) {
        return false;
      }
    }

    return true;
  } catch (error) {
    return false;
  }
}

export { clearEmptyRows, isCsvValid };
