import {
  Skeleton,
  useDevice,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';
import { useResponsiveSlide } from './content-list-slider';
import {
  MockImage,
  MockSlider,
  Resource,
  ResourceText,
  SkeletonContainer,
} from './content-list.styled';

export const ResourceSkeleton = ({
  height,
}: {
  height: number;
}): JSX.Element => {
  return (
    <Resource>
      <MockImage>
        <Skeleton width="100%" height={`${height}`} />
      </MockImage>
      <ResourceText>
        <Skeleton width="100%" height="22" />
        <Skeleton width="60%" height="22" />
      </ResourceText>
    </Resource>
  );
};

export function ContentListSkeleton({
  showTitle = true,
}: {
  showTitle?: boolean;
}): JSX.Element {
  const { slidesToShow } = useResponsiveSlide({});
  const { isTouchableMobileOrTablet } = useDevice();

  const _slidesToShow = isTouchableMobileOrTablet
    ? slidesToShow + 1
    : slidesToShow;

  const { height } = useResourceSkeletonHeight();

  return (
    <SkeletonContainer>
      {showTitle && <Skeleton height="28" width="298" />}
      <MockSlider isTouchable={isTouchableMobileOrTablet}>
        {Array.from({ length: _slidesToShow }, (_, index) => (
          <ResourceSkeleton key={index} height={height} />
        ))}
      </MockSlider>
    </SkeletonContainer>
  );
}

export function useResourceSkeletonHeight() {
  const { slidesToShow } = useResponsiveSlide({});
  const lateralMenuWidth = 272;
  const aspectRatio = 1.8;
  const screenWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  const theme = useGlimmerTheme();

  const height =
    screenWidth > parseInt(theme.breakpoints.md)
      ? (screenWidth * 0.9 - lateralMenuWidth) / slidesToShow / aspectRatio
      : (screenWidth * 0.9) / slidesToShow / aspectRatio;

  return {
    height,
  };
}
