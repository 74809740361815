import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  Course,
  GetCurrentMaterialsQuery,
  Material as QueryMaterial,
  StagedMaterials as QueryStagedMaterials,
} from '@innovamat/glow-api-client';

import { locales } from '@innovamat/localization';
import { cloneDeep } from '@innovamat/radiance-utils';

import type {
  Material,
  ProductTypeOfMaterial,
  StagedMaterials,
} from '../current-materials.types';

import type { MaterialsState } from './use-current-materials-reducer';

export function useParseMaterials(
  currentMaterials: GetCurrentMaterialsQuery['getCurrentMaterials'] | undefined,
  courses: Course[] | undefined,
  init: (state: MaterialsState) => void,
  isInitiated: boolean,
  isSameOrganization: boolean,
  organizationId: string
): void {
  const { t } = useTranslation();
  const mapMaterials = useCallback(
    (material: QueryMaterial): Material => {
      const course = courses?.find(
        (currentCourse) => currentCourse?.order === material.courseOrder
      );

      return {
        controlsCounterValue: material.quantity,
        course: course?.name ?? '-',
        courseOrder: material.courseOrder,
        flagName: material?.languageId || '',
        id: material.productId,
        image: material.imageUrl,
        language: material?.languageId
          ? locales.getInnovamat3(material?.languageId)?.toUpperCase()
          : '',
        maxReturnableItems: material.maxReturnableItems,
        name: material.productShortname,
        productType: material.productType,
        productTypeOfMaterial:
          material.productTypeOfMaterial as ProductTypeOfMaterial,
        quantity: material.quantity,
        stageOrder: material.stageOrder,
        totalOperationQuantity: 0,
      };
    },
    [courses]
  );

  const mapStagedMaterials = useCallback(
    (stage: QueryStagedMaterials): StagedMaterials => {
      return {
        id: stage.id,
        name: stage?.name ? t(`common.stage-${stage.name.toLowerCase()}`) : '-',
        materials: stage.materials.map((material) => mapMaterials(material)),
      };
    },
    [mapMaterials]
  );

  useEffect(() => {
    if (!currentMaterials) {
      return;
    }

    const parsedMaterials = currentMaterials.map((stage) => {
      return (stage as QueryStagedMaterials).materials.map(
        (material: QueryMaterial) => mapMaterials(material)
      );
    });

    const stagedMaterials = currentMaterials.map((stage) =>
      mapStagedMaterials(stage as QueryStagedMaterials)
    );

    if (!isInitiated || !isSameOrganization) {
      init({
        cart: [] as Material[],
        initialStagedMaterials: cloneDeep(stagedMaterials),
        isEditMode: false,
        materials: parsedMaterials.flat(),
        stagedMaterials,
        storedOrganizationId: organizationId,
      } as MaterialsState);
    }
  }, [currentMaterials, isSameOrganization]);
}
