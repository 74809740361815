import {
  IconButton,
  Tabs,
  Tooltip,
  Typography,
  theme,
  useDevice,
} from '@innovamat/glimmer-components';
import { useWindowSize } from '@innovamat/hooks';
import { InnerHtmlParser } from '@innovamat/smart-guides';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../user-management';
import useIsSticky from '../../hooks/use-is-sticky';
import {
  sectionType,
  useHorizontalMenuTabs,
} from '../../providers/session-section-provider';
import HeaderTitle from './header-title';
import {
  ActionsWrapper,
  DefaultHeader,
  Description,
  Divider,
  ExpandableContent,
  ExpandableContentInnerWrapper,
  HeaderWrapper,
  InnerWrapper,
  NonBreakablePrintablesWrapper,
  PrintablesWrapper,
  RightElementsWrapper,
  RowWrapper,
  SecondRowHeader,
  Sticky,
  TitleWrapper,
} from './header.styled';
import { LayoutProps } from './layout';

import { useEventLogging } from '@innovamat/event-logging';
import { stripHTMLTags } from '@innovamat/radiance-utils';

type NewHeaderProps = Omit<
  LayoutProps,
  'children' | 'setScrollPosition' | 'handleDownloadOption'
> & {
  renderNavigation: () => JSX.Element;
  renderActions: () => JSX.Element;
  renderPrintables: (showAssessables: boolean) => JSX.Element;
};

const NewHeader = ({
  isSessionLayout,
  description,
  title,
  id,
  printables,
  renderNavigation,
  renderActions,
  renderPrintables,
  onFullScreenButtonClick,
  onGoBack,
}: NewHeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const innerRef = useRef<HTMLDivElement>(null);
  const { isMobile, isTablet } = useDevice();
  const { headerRef, isSticky, stickyRef } = useIsSticky();
  const { isRole, isLoggedIn } = useUser();

  const showBackButton = Boolean(isLoggedIn);

  const { setEventData } = useEventLogging();

  const {
    isSectionActive,
    changeSectionActive,
    isGuideInResource,
    hasLinkedResources,
    hasAssessableResources,
    hasResources,
  } = useHorizontalMenuTabs();

  const onToggleSession = (sectionActive: sectionType): void => {
    if (sectionActive === 'Related') {
      setEventData('related_content_accessed', {
        session_name: stripHTMLTags(title),
        session_id: id,
      });
    } else {
      setEventData('session_toggle_class_guide', {
        resource_name: stripHTMLTags(title),
        toggle_type: sectionActive,
        session_id: id,
      });
    }
  };

  const titleText = title?.replace('&nbsp;', '');
  const descriptionText = description?.replace('&nbsp;', '');

  const showRelatedItems =
    hasLinkedResources && !isRole.Student && !isRole.Lead && !isRole.Free;
  const showAssessmentItems =
    hasAssessableResources && !isRole.Student && !isRole.Lead && !isRole.Free;
  const showResources = hasResources;
  const showGuide = !isRole.Student && isGuideInResource;
  const showHorizontalMenu =
    (showRelatedItems || showResources || showGuide) && isSessionLayout;

  const hasDescriptionText = !!descriptionText && descriptionText !== '<p></p>';
  const hasPrintables = printables && printables?.length > 0;
  const showSecondRow =
    hasDescriptionText || showHorizontalMenu || hasPrintables;

  const titleRef = useRef<HTMLDivElement>(null);
  const [descriptionWidth, setDescriptionWidth] = useState<number | undefined>(
    titleRef.current?.clientWidth
  );
  const windowSize = useWindowSize();

  function handleDescriptionWidth(): void {
    setDescriptionWidth(titleRef.current?.clientWidth! - 16);
  }

  useEffect(() => {
    handleDescriptionWidth();
  }, [windowSize]);

  useLayoutEffect(handleDescriptionWidth, [titleRef]);

  return (
    <Sticky ref={stickyRef}>
      <HeaderWrapper ref={headerRef}>
        <InnerWrapper
          ref={innerRef}
          hasTabs={Boolean(showHorizontalMenu)}
          isSticky={isSticky}
        >
          <DefaultHeader isSticky={isSticky}>
            <TitleWrapper ref={titleRef}>
              {showBackButton && (
                <Tooltip content={t('back.button')}>
                  <IconButton
                    icon="BackIcon"
                    onClick={onGoBack}
                    data-inlinemanual="courseselector"
                    data-testid="goBackSelector"
                  />
                </Tooltip>
              )}

              {(isSticky || !isTablet) && (
                <HeaderTitle
                  isSticky={isSticky}
                  titleText={titleText}
                  onFullScreenButtonClick={onFullScreenButtonClick}
                  showFSButton={!!isSessionLayout}
                  dataInlinemanual={
                    isSectionActive('Guide') ? 'genially_fullscreen' : undefined
                  }
                />
              )}
            </TitleWrapper>
            {showBackButton && (
              <RightElementsWrapper>
                <ActionsWrapper>{renderActions()}</ActionsWrapper>
                <Divider />
                {renderNavigation()}
              </RightElementsWrapper>
            )}
          </DefaultHeader>
          <ExpandableContent
            isExpanded={!isSticky}
            hasBackButton={showBackButton}
          >
            <ExpandableContentInnerWrapper isExpanded={!isSticky}>
              {isTablet && (
                <HeaderTitle
                  isSticky={isSticky}
                  titleText={titleText}
                  onFullScreenButtonClick={onFullScreenButtonClick}
                  showFSButton={!!isSessionLayout}
                  dataInlinemanual={
                    isSectionActive('Guide') ? 'genially_fullscreen' : undefined
                  }
                />
              )}
              {showSecondRow && (
                <SecondRowHeader>
                  {descriptionText && (
                    <Description maxWidth={descriptionWidth}>
                      <Typography.Body1
                        color={
                          theme.tokens.color.alias.cm.text['text-subtle'].value
                        }
                      >
                        <InnerHtmlParser
                          text={{
                            body: descriptionText || '',
                            id: 'descriptionText',
                          }}
                        />
                      </Typography.Body1>
                    </Description>
                  )}
                  {!showHorizontalMenu && (
                    <NonBreakablePrintablesWrapper>
                      {renderPrintables(false)}
                    </NonBreakablePrintablesWrapper>
                  )}
                </SecondRowHeader>
              )}

              {showHorizontalMenu && (
                <RowWrapper>
                  <Tabs
                    tabs={[
                      {
                        selected: isSectionActive('Guide'),
                        tooltip: isMobile ? t('Sessio.Guia') : undefined,
                        text: isMobile ? undefined : t('Sessio.Guia'),
                        icon: 'GuideIcon',
                        onClick: () =>
                          changeSectionActive('Guide', onToggleSession),
                        showCondition: showGuide,
                        dataTestId: 'guideButtonResource',
                      },
                      {
                        selected: isSectionActive('Session'),
                        tooltip: isMobile ? t('Sessio.SessioAula') : undefined,
                        text: isMobile ? undefined : t('Sessio.SessioAula'),
                        icon: 'PlayerIcon',
                        onClick: () =>
                          changeSectionActive('Session', onToggleSession),
                        showCondition: showResources,
                        dataTestId: 'sessionButtonResource',
                      },
                      {
                        selected: isSectionActive('Assessment'),
                        tooltip: isMobile ? t('session.assessment') : undefined,
                        text: isMobile ? undefined : t('session.assessment'),
                        icon: 'EvaluationIcon',
                        onClick: () =>
                          changeSectionActive('Assessment', onToggleSession),
                        showCondition: showAssessmentItems,
                        dataTestId: 'assessmentButtonResource',
                      },
                      {
                        selected: isSectionActive('Related'),
                        tooltip: isMobile
                          ? t('Sessio.RelatedItems')
                          : undefined,
                        text: isMobile ? undefined : t('Sessio.RelatedItems'),
                        icon: 'RelatedItemsIcon',
                        onClick: () =>
                          changeSectionActive('Related', onToggleSession),
                        showCondition: showRelatedItems,
                        dataTestId: 'relatedButtonResource',
                      },
                    ]}
                  />

                  <PrintablesWrapper>
                    {renderPrintables(!!showAssessmentItems)}
                  </PrintablesWrapper>
                </RowWrapper>
              )}
            </ExpandableContentInnerWrapper>
          </ExpandableContent>
        </InnerWrapper>
      </HeaderWrapper>
    </Sticky>
  );
};

export default NewHeader;
