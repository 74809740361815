import { useTranslation } from 'react-i18next';

import { Column } from '@innovamat/glimmer-components';

import { Test } from '../../../tests-and-results';

const useTestsAndResultsColumnsConfiguration = (): Column<Test>[] => {
  const { t } = useTranslation();

  return [
    {
      id: 'roundName',
      label: t('testsAndResultsView.testNameColumnLabel'),
      sortable: true,
      sortIgnoreCase: true,
      width: 300,
      minWidth: 300,
    },
    {
      id: 'status',
      label: t('common.status'),
      width: 150,
      minWidth: 150,
    },
    {
      id: 'password',
      label: t('common.password'),
      width: 150,
      minWidth: 150,
    },
    {
      id: 'startDate',
      width: 1,
      separator: true,
    },
    {
      id: 'startDate',
      label: t('common.availableFrom'),
      isLargeLabel: false,
      sortable: true,
      width: 150,
      minWidth: 150,
    },
    {
      id: 'endDate',
      label: t('common.availableUntil'),
      isLargeLabel: false,
      sortable: true,
      width: 150,
      minWidth: 150,
    },
    {
      id: 'report',
      label: t('testsAndResultsView.reportColumnLabel'),
      isLargeLabel: false,
      sortable: true,
      width: 150,
      minWidth: 150,
    },
  ];
};

export { useTestsAndResultsColumnsConfiguration };
