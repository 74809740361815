import styled from '@emotion/styled';
import { Tooltip } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { StandardTag } from '../../types/SmartGuide';
import { SimpleBlock } from '../SimpleBlock';
import { Tag } from '../Tag';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media print {
    gap: 4px;

    .tag-container {
      background-color: ${({ theme }) =>
        theme.tokens.color.global.neutral.neutral200.value};
    }

    .tag-text {
      color: ${({ theme }) =>
        theme.tokens.color.alias.cm.text['text-subtle'].value};
    }
  }
`;

const StyledTooltip = styled(Tooltip)`
  overflow: hidden;

  *:hover {
    filter: brightness(0.95);
  }
`;

type Props = {
  tags?: StandardTag[];
};

export function StandardTags({ tags }: Props) {
  const { t } = useTranslation();

  if (!tags || !tags.length) {
    return null;
  }

  const title = t('digitalguides.standards');

  return (
    <SimpleBlock title={title}>
      <Container>
        {tags.map((tag) => {
          return (
            <StyledTooltip content={t(tag.tooltipKey)}>
              <Tag text={tag.text} isEnabled={true} />
            </StyledTooltip>
          );
        })}
      </Container>
    </SimpleBlock>
  );
}
