type Report = { deliveryDate?: string | null };

const isAvailableForDownload = (
  deliveryDate: Report['deliveryDate']
): boolean => {
  return deliveryDate ? new Date() > new Date(deliveryDate) : false;
};

export { isAvailableForDownload };
export type { Report };
