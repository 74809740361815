import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {
  gaTranslations,
  reportsTranslations,
  geogebraTranslations,
} from '@innovamat/poeditor';
// Define dynamic imports for all languages
import { routes } from './routes';

const dynamicImports = {
  es: () => {
    return {
      translations: {
        ...gaTranslations.es,
        ...reportsTranslations.es,
        ...geogebraTranslations.es,
        ...routes.es,
      },
    };
  },
  ca: () => {
    return {
      translations: {
        ...gaTranslations.ca,
        ...reportsTranslations.ca,
        ...geogebraTranslations.ca,
        ...routes.ca,
      },
    };
  },
  eu: () => {
    return {
      translations: {
        ...gaTranslations.eu,
        ...reportsTranslations.eu,
        ...geogebraTranslations.eu,
        ...routes.eu,
      },
    };
  },
  en: () => {
    return {
      translations: {
        ...gaTranslations.en,
        ...reportsTranslations.en,
        ...geogebraTranslations.en,
        ...routes.en,
      },
    };
  },
  it_IT: () => {
    return {
      translations: {
        ...gaTranslations.it,
        ...reportsTranslations.it,
        ...geogebraTranslations.it,
      },
    };
  },
  en_US: () => {
    return {
      translations: {
        ...gaTranslations.en_us,
        ...reportsTranslations.en_us,
        ...geogebraTranslations.en_us,
      },
    };
  },
  pt_BR: () => {
    return {
      translations: {
        ...gaTranslations.pt_br,
        ...reportsTranslations.pt_br,
        ...geogebraTranslations.pt_br,
      },
    };
  },
  va: () => {
    return {
      translations: {
        ...gaTranslations.va_es,
        ...reportsTranslations.va_es,
        ...geogebraTranslations.va_es,
      },
    };
  },
  es_LA: () => {
    return {
      translations: {
        ...gaTranslations.es_la,
        ...reportsTranslations.es_la,
        ...geogebraTranslations.es_la,
      },
    };
  },
  // Add other languages similarly...
};

const resources: any = {};

// Populate resources with the dynamic imports
Object.keys(dynamicImports).forEach(async (key) => {
  resources[key] = await dynamicImports[key as keyof typeof dynamicImports]();
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
i18n.use(initReactI18next).init({
  lng: 'es',
  resources,
  fallbackLng: (code: string) => {
    if (!code || code === '') return 'es';
    return code.includes('es_') ? 'es_LA' : code;
  },
  whitelist: Object.keys(resources),
  debug: false,
  ns: ['translations', 'routes'],
  defaultNS: 'translations',
  keySeparator: false,
  nsSeparator: '::',
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
});

export default i18n;
