import { useEventLogging } from '@innovamat/event-logging';
import { Resource } from '@innovamat/resource-viewer';
import { useEffect } from 'react';

type Props = {
  resource: Resource | undefined;
};

export function useResourceEvents({ resource }: Props): void {
  const { setEventData } = useEventLogging();

  useEffect(() => {
    if (resource?.id) {
      const isSession = resource && 'resources' in resource;
      const hasFileId = 'fileId' in resource ? resource.fileId : undefined;
      setEventData('resource_accessed', {
        resource_id: resource.id,
        resource_name: resource.title,
        resource_type: resource.type,
        file_id: isSession ? '' : hasFileId,
      });
    }
  }, [resource?.id]);
}
