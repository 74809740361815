import { useEffect, useState } from 'react';

type Props<T> = {
  trigger: T;
};

export function useFakeLoading<T>({ trigger }: Props<T>): boolean {
  const [isFakeLoading, setIsFakeLoading] = useState(true);

  useEffect(() => {
    setIsFakeLoading(true);
    const timer = setTimeout(() => {
      setIsFakeLoading(false);
    }, 2800); // 2800ms is the time it takes for the loading gif to complete half cycle

    return () => {
      setIsFakeLoading(true);
      clearTimeout(timer);
    };
  }, [trigger]);

  return isFakeLoading;
}
