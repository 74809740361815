import styled from '@emotion/styled';
import CellFluency from '../cell-fluency';
import { Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { CardType } from '@innovamat/glow-api-client';
import { CELL_VARIANT } from '../cell-fluency/cell-fluency';

type LegendItemProps = {
  status: CardType;
  label: string;
  allCellsLocked?: boolean;
};

export type ModeLegend = 'allClassroomMode' | 'studentMode';

type Props = {
  allCellsLocked?: boolean;
  mode?: ModeLegend;
};

const ITEMS_PER_ROW = 3;

const LegendContainer = styled.div<{ mode: ModeLegend }>`
  display: flex;
  flex-direction: ${({ mode }) =>
    mode === 'allClassroomMode' ? 'column' : 'row'};
  justify-content: center;
  align-items: center;
  gap: ${({ mode }) => (mode === 'allClassroomMode' ? '4px' : '24px')};
  padding-left: 32px;
`;

const LegendItemWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 8px;
`;

const LegendRow = styled(Typography.Body2)`
  display: flex;
  gap: 32px;
  justify-content: center;
  color: ${({ theme }) => theme.tokens.color.alias.cm.text['text'].value};
`;

const StyledLegendText = styled(Typography.Body2)<{ allCellsLocked?: boolean }>`
  color: ${({ allCellsLocked, theme }) =>
    allCellsLocked
      ? `${theme.tokens.color.alias.cm.text['text-disabled'].value}`
      : `${theme.tokens.color.alias.cm.text['text'].value}`};
`;

const LegendItem = ({
  status,
  label,
  allCellsLocked,
}: LegendItemProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <LegendItemWrapper>
      <CellFluency
        variant={CELL_VARIANT.CELL_SMALL_SIZE}
        status={status}
        allCellsLocked={allCellsLocked}
      />
      <StyledLegendText allCellsLocked={allCellsLocked}>
        {t(label)}
      </StyledLegendText>
    </LegendItemWrapper>
  );
};

function LegendFluency({
  mode = 'allClassroomMode',
  allCellsLocked,
}: Props): JSX.Element {
  const statusLabels: Partial<Record<CardType, string>> = {
    notSeen: 'fluency.reports.legend.gray',
    inProgress: 'fluency.reports.legend.lightGreen',
    memorized: 'fluency.reports.legend.darkGreen',
    fairlyFailed: 'fluency.reports.legend.orange',
    mostlyFailed:
      mode === 'allClassroomMode'
        ? 'fluency.reports.legend.red'
        : 'fluency.reports.legend.redStudent',
  };

  const filteredStatusLabels = Object.entries(statusLabels).filter(
    ([status]) => !(mode === 'studentMode' && status === 'fairlyFailed')
  );

  const renderItems = (): JSX.Element[] =>
    filteredStatusLabels.map(([status, label]) => (
      <LegendItem
        key={status}
        status={status as CardType}
        label={label}
        allCellsLocked={allCellsLocked}
      />
    ));

  const renderRows = (items: JSX.Element[]): JSX.Element => (
    <>
      <LegendRow>{items.slice(0, ITEMS_PER_ROW)}</LegendRow>
      <LegendRow>{items.slice(ITEMS_PER_ROW)}</LegendRow>
    </>
  );

  const items = renderItems();

  return (
    <LegendContainer mode={mode}>
      {mode === 'allClassroomMode' ? renderRows(items) : items}
    </LegendContainer>
  );
}

export default LegendFluency;
