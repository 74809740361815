import styled from '@emotion/styled';
import { hideRootElement, showRootElement } from '@innovamat/radiance-utils';
import React, { useEffect, useRef } from 'react';
import { useGetPageFromUrl } from '../../hooks/use-get-page-from-url';
import { Option } from '../../types/option';
import NewHeader from './header';
import NewLayoutActions from './header.actions';
import NewLayoutPrintables from './header.printables';
import { NewLayoutNavigation } from './layout.navigation';

const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  height: var(--realVh);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.dark['01']};
  margin: 0 auto;
`;

const SecondWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.dark['01']};
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
`;

const Content = styled.div<{
  isSessionLayout: boolean;
}>`
  height: calc(calc(var(--vh, 1vh) * 100));
  max-width: 1440px;
  padding: var(--heightHeader) 24px;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    padding: var(--heightHeader) 60px;
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: var(--heightHeader) 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: var(--heightHeader) 16px;
  }
`;

export type LayoutProps = {
  children: React.ReactNode;
  title: string | undefined;
  description: string | undefined;
  id: string;
  printables?: Option[];
  handleDownloadOption: (option?: Option | undefined) => void;
  setScrollPosition: (value: any) => void; // TODO: Add SCROLLPOSITION type
  isSessionLayout?: boolean;
  onFullScreenButtonClick?: () => void;
  onGoBack?: () => void;
};

export function Layout({
  children,
  setScrollPosition,
  isSessionLayout,
  ...rest
}: LayoutProps): JSX.Element {
  const { id, printables, handleDownloadOption } = rest;
  const { page, getResourceById } = useGetPageFromUrl();
  const listInnerRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);

  const resource = getResourceById(id);

  useEffect(() => {
    hideRootElement();
    return () => {
      showRootElement();
    };
  }, []);

  const renderPrintables = (showAssessables: boolean): JSX.Element => (
    <NewLayoutPrintables
      printables={printables}
      handleDownloadOption={handleDownloadOption}
      showAssessables={showAssessables}
    />
  );

  const renderActions = (): JSX.Element => (
    <NewLayoutActions resource={resource} />
  );

  const renderNavigation = (): JSX.Element => (
    <NewLayoutNavigation page={page} />
  );

  return (
    <Wrapper ref={listInnerRef}>
      <SecondWrapper>
        <NewHeader
          {...rest}
          isSessionLayout={!!isSessionLayout}
          renderActions={renderActions}
          renderNavigation={renderNavigation}
          renderPrintables={renderPrintables}
        />
        <Content isSessionLayout={!!isSessionLayout} ref={childrenRef}>
          {children}
        </Content>
      </SecondWrapper>
    </Wrapper>
  );
}
