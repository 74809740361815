import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
  BannerToDisplay,
  ClassroomStudents,
  ClassroomTeachers,
  CloseButtonFullScreen,
  ERROR_TYPE,
  ErrorPage,
  StudentViewButton,
  TestsAndResults,
  useCurrentNavigationValues,
  useNavigation,
  useReportsFullScreen,
  useShowStudentView,
  useUser,
} from '@innovamat/ga-features';
import { useEffect } from 'react';
import { useParams } from 'react-router-domv6';
import { useSitemapNavigation } from '../../hooks/use-sitemap-navigation';
import { Header } from '../layout/header';
import { MainLayout } from '../layout/main-layout';
import { PageWithTableWrapper } from '../layout/page-with-table';
import { ImportsPage } from '../pages/imports-page';
import { ClassroomSelector } from './classroom-selector';
import { ContentPageRouter } from './content-page-router';
import { DigitalPracticeContent } from './digital-practice-content';
import { ImportCSVWrapper } from './import-csv-wrapper';
import { ReportsNavigationWrapper } from './reports-navigation-wrapper';
import { SessionDoneProviderWrapper } from './session-done-provider';

const StyledBanners = styled(BannerToDisplay)`
  padding: 0 32px 24px 0;
`;

const StyledMainLayout = styled(MainLayout)<{ isOverflowVisible: boolean }>`
  ${({ isOverflowVisible }) => {
    if (isOverflowVisible) {
      return css`
        .page-frame {
          overflow-y: visible;
          overflow-x: visible;
        }
      `;
    }
    return ``;
  }};
`;
type ClassroomStaticMenus =
  | 'teachers'
  | 'students'
  | 'reports'
  | 'general'
  | 'tests-and-results'
  | 'imports';

type Params = {
  classroomId: string;
  menu?: ClassroomStaticMenus;
  submenu?: string;
};

export function ClassroomNavigationWrapper(): JSX.Element {
  const { user, isStudentViewActive } = useUser();
  const { menu, submenu } = useParams<Params>();
  const { currentClassroom } = useCurrentNavigationValues();
  const { goBackOrHome } = useNavigation();
  const { sitemap, isPending, sitemapTree, isPageIdAsSubmenu } =
    useSitemapNavigation();
  const courseOrder = currentClassroom?.courseOrder;
  const { isFullScreen, closeFullScreen, openFullScreen } =
    useReportsFullScreen();

  const { showStudentViewButton } = useShowStudentView({
    courseOrder: courseOrder,
    menu,
  });

  const isYearUpdate =
    !currentClassroom?.checked ||
    (!!currentClassroom?.provider && !currentClassroom?.providerChecked);

  const title = sitemap?.value || '';

  const pageId = isPageIdAsSubmenu
    ? submenu
    : sitemap?.fullPageId || sitemap?.demoPageId;

  const submenusRequiringFullScreen = new Set([
    'last-year',
    'general-progress',
    'weekly-results',
  ]);
  const shouldHideLateralMenuWithTransition = menu === 'reports';

  useEffect(() => {
    if (submenu && submenusRequiringFullScreen.has(submenu)) {
      openFullScreen();
    }
  }, [submenu]);

  if (!user) return <></>;

  const sitemapTreeError = !isPending && sitemapTree.length === 0;

  const getComponentRoute = (): JSX.Element => {
    if (isStudentViewActive && user && menu === 'general') {
      return <DigitalPracticeContent />;
    }

    if (sitemapTreeError) return <ErrorPage errorType={ERROR_TYPE.ERROR_500} />;

    switch (menu) {
      case 'teachers':
        return (
          <PageWithTableWrapper>
            <ClassroomTeachers />
          </PageWithTableWrapper>
        );
      case 'students':
        return (
          <ImportCSVWrapper>
            <PageWithTableWrapper>
              <ClassroomStudents />
            </PageWithTableWrapper>
          </ImportCSVWrapper>
        );
      case 'reports':
        return <ReportsNavigationWrapper />;
      case 'tests-and-results':
        return (
          <PageWithTableWrapper>
            <TestsAndResults />
          </PageWithTableWrapper>
        );
      case 'imports':
        return (
          <PageWithTableWrapper>
            <ImportsPage />
          </PageWithTableWrapper>
        );
      default:
        return (
          <>
            <StyledBanners />
            <ContentPageRouter
              title={title}
              pageId={pageId}
              isYearUpdate={isYearUpdate}
            />
          </>
        );
    }
  };

  const handleCloseFullScreen = (): void => {
    if (submenu === 'last-year') {
      goBackOrHome();
    }
    closeFullScreen();
  };

  return (
    <>
      <Header
        leftActions={
          isFullScreen ? (
            <CloseButtonFullScreen
              onCloseFullScreen={() => handleCloseFullScreen()}
            />
          ) : (
            <ClassroomSelector />
          )
        }
        sitemapTree={sitemapTree}
        rightActions={
          showStudentViewButton ? <StudentViewButton size="M" /> : undefined
        }
        hasBackButton
      />
      <StyledMainLayout
        sitemapTree={sitemapTree}
        isOverflowVisible={menu === 'reports'}
        hideLateralMenu={
          sitemapTreeError || isFullScreen || submenu === 'upload'
        }
        hideLateralMenuWithTransition={shouldHideLateralMenuWithTransition}
      >
        <SessionDoneProviderWrapper>
          {getComponentRoute()}
        </SessionDoneProviderWrapper>
      </StyledMainLayout>
    </>
  );
}
