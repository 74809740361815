import { Page } from './types';

export function mapPageResponse(
  page: any,
  completedResources: any,
  isStudent: boolean
): Page {
  return {
    id: page.uuid,
    name: processText(page.name),
    description: processText(page.description),
    content_lists: page.ordered_translated_resource_lists.map((list: any) =>
      mapContentListResponse(list, completedResources, isStudent)
    ),
  };
}

export function mapContentListResponse(
  contentList: any,
  completedResources: any,
  isStudent: boolean
) {
  return {
    id: contentList.translated_resource_list.uuid,
    name: processText(contentList.translated_resource_list.name),
    description: processText(contentList.translated_resource_list.description),
    list_type: contentList.translated_resource_list.list_type,
    page_id: contentList.translated_resource_list.page_id,
    total_resources: contentList.translated_resource_list.total_resources,
    resources:
      contentList.translated_resource_list.translated_ordered_resources.map(
        (resource: any) =>
          mapResource(
            resource.resource_view,
            resource.order,
            completedResources,
            isStudent
          )
      ),
  };
}

function mapResource(
  resource: any,
  order: number,
  completedResources: any,
  isStudent: any
) {
  const status = [];

  if (resource.visibility === 'blocked') {
    status.push('blocked');
  }

  if (
    isStudent &&
    resource.type !== 'session' &&
    resource.visibility === 'not_done' &&
    resource.done_status === 'not_done'
  ) {
    status.push('blocked');
  } else if (resource.visibility === 'not_done' && isStudent) {
    status.push('not_done');
  }

  if (resource.visibility === 'done') {
    status.push('done');
  }

  if (resource.icon === 'evaluation') {
    status.push('evaluable');
  }

  if (resource.status === 'not_available') {
    status.push('not_available');
  }

  if (resource.status === 'jumpable' && !isStudent) {
    status.push('jumpable');
  }

  if (resource.status === 'new') {
    status.push('new');
  }

  if (resource.icon === 'download') {
    status.push('downloadable');
  }

  if (
    resource.type === 'vimeo' &&
    resource.done_status === 'done' &&
    !isStudent
  ) {
    status.push('already_seen');
  }

  const solutionsEnabled =
    completedResources?.find((r: any) => r.resourceId === resource.uuid)
      ?.solutionsEnabled || false;

  const isCompletedResource = completedResources?.some(
    (r: any) => r.resourceId === resource.uuid
  );

  return {
    id: resource.uuid,
    order: order,
    name: processText(resource.name),
    description: processText(resource.description),
    thumbnail: resource.thumbnail.href,
    type: resource.type,
    status: status,
    enumeration: resource.enumeration,
    code: resource.code,
    is_completable: !isStudent && resource.is_completable,
    has_solutions: resource.has_solutions,
    is_done: isCompletedResource,
    solutions_enabled: solutionsEnabled,
    has_digital_guide: resource.has_digital_guide,
    video_info: {
      duration: resource.duration,
      progress: status.includes('already_seen')
        ? 100
        : getResourceProgress(resource.duration, resource.progress),
    },
  };
}

function getResourceProgress(duration: any, progress: any) {
  const getNotSeenDuration = (
    duration: number | undefined,
    progress: number
  ) => {
    return duration ? (duration * (100 - progress)) / 100 : 0;
  };

  const isVideoAlmostSeen = (
    duration: number | undefined,
    progress: number
  ) => {
    return progress >= 90 && getNotSeenDuration(duration, progress) <= 60;
  };

  return isVideoAlmostSeen(duration, progress) ? 100 : progress;
}

function processText(text: string) {
  // strip HTML tags
  const processedText = text.replace(/<[^>]*>/g, '').replace('/n', '');

  // handle Missing Location
  return text === 'Missing locale' ? '' : processedText;
}
