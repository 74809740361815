import styled from '@emotion/styled';

import { getTypography, Typography } from '@innovamat/glimmer-components';

import { groupConsecutiveTips } from '../../digital-guides/utils/steps';
import { usePartColors } from '../../hooks/usePartColors';
import { PrintableBody1 } from '../../printable-styles/printable-typography';
import type { Content, Evaluable, PartType } from '../../types/SmartGuide';
import { IconEvaluable, IconEvaluablePrintable } from '../IconEvaluable';
import { ManagementComment } from '../ManagementComment';
import { Media } from '../Media';
import { PageBreak } from '../page-break';
import { PreprInnerHtml } from '../PreprInnerHtml';
import { SignedTip } from '../signed-tip';
import { Solution } from '../Solution';
import { GroupedStep } from '../step/grouped-step';
import { Table } from '../Table';
import { Tip } from '../Tip';
import { WhatToObserve } from '../WhatToObserve';

const NumContainer = styled.div`
  width: 24px;
  min-width: 24px;
  display: grid;
  place-items: center;
  margin: 8px 4px 0 0;
  color: ${({ color }) => color};

  @media print {
    margin: 0 2px 0 0;
  }
`;

const StepContainer = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 100%;
`;

const StepContent = styled.div`
  padding: 8px 0 8px 16px;
  border-left: 1px solid;
  border-color: ${({ color }) => color};
  display: flex;
  flex: 1;
  width: 1%;
  flex-direction: column;
  gap: 16px;

  @media print {
    padding: 2px 0 2px 8px;
    gap: 8px;
  }
`;

const NumberText = styled(Typography.Body1)`
  ${({ theme }) => getTypography(theme, 'Subtitle 2')}
`;

const StepText = styled(PrintableBody1)`
  gap: 16px;
  display: flex;
  justify-content: space-between;

  @media print {
    color: #000000;
    gap: 8px;
  }
`;

const TextComponent = styled(PrintableBody1)``;

const StyledIconEvaluable = styled(IconEvaluable)`
  height: auto !important;
`;

const StyledIconEvaluablePrintable = styled(IconEvaluablePrintable)`
  height: auto;

  > div {
    height: auto;
  }
`;

type Props = {
  num?: number | null;
  part: PartType;
  content: Content[];
  evaluable?: Evaluable;
  isPrintable?: boolean;
};

export function NumberedStep({
  num,
  part,
  content,
  evaluable,
  isPrintable,
}: Props): JSX.Element {
  const { getElement } = usePartColors();
  const color = getElement(part);

  const renderFirstText = (firstChild: Content) => {
    if (firstChild.__typename !== 'Text') return null;
    if (typeof firstChild.text !== 'string')
      return (
        <StepText key={`first-child`}>
          <p>{firstChild.text}</p>
        </StepText>
      );
    return (
      <StepText key={`first-child`}>
        <PreprInnerHtml text={firstChild.text} />
        {evaluable?.isEvaluable && !isPrintable && (
          <StyledIconEvaluable dimension={evaluable.dimension} />
        )}
        {evaluable?.isEvaluable && isPrintable && (
          <StyledIconEvaluablePrintable dimension={evaluable.dimension} />
        )}
      </StepText>
    );
  };

  const renderChildren = () => {
    if (!content) return null;

    return groupConsecutiveTips(content).map((group, index: number) => {
      return (
        <GroupedStep
          key={`${group.__typename}-${index}`}
          typename={group.__typename}
        >
          {group.steps.map((child, index: number) => {
            if (!child) return null;

            switch (child.__typename) {
              case 'Text':
                if (index === 0) return renderFirstText(child);
                return typeof child.text === 'string' ? (
                  <TextComponent>
                    <PreprInnerHtml
                      key={`${child.__typename}-${index}`}
                      text={child.text}
                    />
                  </TextComponent>
                ) : (
                  <TextComponent>{child.text}</TextComponent>
                );

              case 'ConsejoDeGestin':
                if (
                  index > 1 &&
                  content[index - 1].__typename === 'ConsejoDeGestin'
                )
                  return null;
                return (
                  <ManagementComment
                    key={`${child.__typename}-${index}`}
                    text={child.text}
                    media={child.media}
                    tables={child.tables}
                  />
                );

              case 'Formacin':
                return (
                  <ManagementComment
                    key={`${child.__typename}-${index}`}
                    text={child.text}
                    media={child.media}
                    tables={child.tables}
                  />
                );

              case 'Ampliacin':
                return (
                  <Tip
                    isPrintable={isPrintable}
                    key={`${child.__typename}-${index}`}
                    type="ampliation"
                    media={child.media}
                    tables={child.tables}
                  >
                    {child.text}
                  </Tip>
                );

              case 'Apoyo':
                return (
                  <Tip
                    isPrintable={isPrintable}
                    key={`${child.__typename}-${index}`}
                    type="support"
                    media={child.media}
                    tables={child.tables}
                  >
                    {child.text}
                  </Tip>
                );

              case 'Signedtip':
                return (
                  <SignedTip
                    key={`${child.__typename}-${index}`}
                    isPrintable={isPrintable}
                    media={child.media}
                    tables={child.tables}
                    type={child.tip_type}
                  >
                    {child.text}
                  </SignedTip>
                );

              case 'Solucin':
                return (
                  <Solution
                    isPrintable={isPrintable}
                    key={`${child.__typename}-${index}`}
                    part={part}
                    media={child.media}
                    tables={child.tables}
                  >
                    {child.text}
                  </Solution>
                );

              case 'QuePodemosObservar':
                return (
                  <WhatToObserve
                    isPrintable={isPrintable}
                    key={`${child.__typename}-${index}`}
                    whatToObserve={child.items}
                  />
                );

              case 'ZzImagen':
                if (!child.media) return null;
                return (
                  <Media
                    key={`${child.__typename}-${index}`}
                    media={child.media}
                  />
                );

              case 'TableSmartguides':
                return <Table tables={child.tables} />;

              case 'PageBreak':
                return <PageBreak />;

              default:
                return null;
            }
          })}
        </GroupedStep>
      );
    });
  };

  return (
    <StepContainer>
      <NumContainer color={color}>
        <NumberText>{num !== null ? num : ''}</NumberText>
      </NumContainer>
      <StepContent color={color}>{renderChildren()}</StepContent>
    </StepContainer>
  );
}
