import { useMemo, useRef } from 'react';
import { usePaginatedListQuery } from '../../generated/types';
import { useCompletedResources } from '../use-completed-resources';
import { mapContentListResponse } from './mapper';
import { ContentListType, ContentResource } from './types';

type Props = {
  academicYearId: string;
  classroomId?: string;
  isStudentView?: boolean;
  list: ContentListType;
  isPaginationEnabled?: boolean;
};

function usePaginatedList({
  academicYearId,
  classroomId,
  list,
  isStudentView,
  isPaginationEnabled,
}: Props) {
  const { data, refetch } = usePaginatedListQuery<{
    paginatedList: ContentListType;
  }>(
    {
      body: {
        academicYearId,
        classroomId: classroomId ?? '',
        listId: list.id,
        paginated: false,
      },
    },
    {
      enabled: false,
    }
  );

  const isFeched = useRef(false);

  const completedResources = useCompletedResources({
    classroomId,
    academicYearId,
  });

  const mappedList = useMemo(() => {
    if (data?.paginatedList) {
      return mapContentListResponse(
        data?.paginatedList,
        completedResources,
        Boolean(isStudentView)
      ) as ContentListType;
    }
    if (list.total_resources === list.resources.length) return list;

    const firstOrder = list.resources[0].order;
    const lastOrder = list.resources[list.resources.length - 1].order;
    const totalResources = list.total_resources;

    const fakeResource: ContentResource = {
      id: 'fake-resource',
      name: '',
      order: 0,
      description: '',
      thumbnail: '',
      type: 'session',
      status: [],
      enumeration: '',
      is_completable: false,
      has_solutions: false,
      code: '',
      is_done: false,
      solutions_enabled: false,
      video_info: {
        duration: 0,
        progress: 0,
      },
    };

    const numberOfFakeResourcesBehind = firstOrder;
    const numberOfFakeResourcesAfter = totalResources - lastOrder - 1;

    const fakeResourcesBehind = Array.from(
      { length: numberOfFakeResourcesBehind },
      () => fakeResource
    );

    const fakeResourcesAfter = Array.from(
      { length: numberOfFakeResourcesAfter },
      () => fakeResource
    );

    const resources = [
      ...fakeResourcesBehind,
      ...list.resources,
      ...fakeResourcesAfter,
    ];

    return {
      ...list,
      resources: resources,
    };
  }, [list, data?.paginatedList, completedResources, isStudentView]);

  const handleRequestAllList = () => {
    if (!isPaginationEnabled) return;
    if (list.total_resources === list.resources.length) return;
    if (isFeched.current) return;
    refetch();
    isFeched.current = true;
  };

  return {
    paginatedList: mappedList,
    onRequestAllList: handleRequestAllList,
  };
}

export { usePaginatedList };
