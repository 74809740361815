import defaultAppletThumbnail from './defaultApplet.png';
import { useTranslation } from 'react-i18next';
import {
  Tooltip,
  Icon,
  theme,
  ResourceCard,
} from '@innovamat/glimmer-components';
import { Applet, WeeklyPractice } from '@innovamat/glow-api-client';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { normalizeImgSource } from '@innovamat/ga-features';
import LogoLoading from './logo_loading.gif';
import React, { useMemo } from 'react';

const Title = styled.h4`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;
`;

const Container = styled.div<{ isDisabled: boolean }>`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};

  img {
    z-index: 0 !important;
    min-height: auto !important;
  }

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.xl}) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: ${theme.breakpoints.lg}) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      grid-template-columns: 1fr;
    }
  `};
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const LoadingContainer = styled.div`
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export function Loading(): JSX.Element {
  const uniqueKey = useMemo(() => Date.now(), []);
  const LogoLoadingSrc = normalizeImgSource(LogoLoading);

  return (
    <img
      alt="Loading"
      src={`${LogoLoadingSrc}?${uniqueKey}`}
      width={64}
      height={64}
    />
  );
}

type Props = {
  session: WeeklyPractice | null | undefined;
  handleStartApplet: (applet: Applet) => void;
  loading: boolean;
  loadMore: boolean;
  isStudentViewActive: boolean;
};

const CatchUpZoneTitle = ({
  isStudentViewActive,
  t,
}: {
  isStudentViewActive: boolean;
  t: (key: string) => string;
}): JSX.Element => (
  <Title>
    {t('weeklyPractice.subtitle.catchUpZone')}
    <RelativeContainer>
      <Tooltip
        content={
          isStudentViewActive
            ? t('weeklyPractice.toolTip.catchUpZone.studentViewMode')
            : t('weeklyPractice.toolTip.catchUpZone')
        }
        popperOptions={{ strategy: 'fixed' }}
        placement="right"
        maxWidth={165}
      >
        <StyledIcon
          icon="InfoIcon"
          size="M"
          hasStateLayer
          iconColor="white"
          bgColor={theme.tokens.color.alias.cm.icon['icon-info'].value}
        />
      </Tooltip>
    </RelativeContainer>
  </Title>
);

const AppletCards: React.FC<{
  activities: Applet[];
  handleStartApplet: (applet: Applet) => void;
  t: (key: string) => string;
}> = ({ activities, handleStartApplet, t }) => (
  <>
    {activities.map((applet) => (
      <ResourceCard
        key={`${applet.pack}${applet.sceneName}`}
        title={applet.data?.name || applet.sceneName || ''}
        type="applet"
        description={applet.data?.description || ''}
        thumbnail={
          applet.data?.thumbnail || normalizeImgSource(defaultAppletThumbnail)
        }
        onClick={() => handleStartApplet(applet)}
        t={t}
        stars={applet.stars}
      />
    ))}
  </>
);

function CatchUpZone({
  session,
  handleStartApplet,
  loading,
  loadMore,
  isStudentViewActive,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const validActivities = useMemo(
    () =>
      (session?.activities || []).filter(
        (activity): activity is Applet => activity != null
      ),
    [session]
  );

  if (validActivities.length === 0 && !loadMore) {
    return <></>;
  }

  return (
    <>
      <CatchUpZoneTitle isStudentViewActive={isStudentViewActive} t={t} />
      <Container isDisabled={isStudentViewActive}>
        <AppletCards
          activities={validActivities}
          handleStartApplet={handleStartApplet}
          t={t}
        />
      </Container>
      <LoadingContainer>{loading && <Loading />}</LoadingContainer>
    </>
  );
}

export default CatchUpZone;
