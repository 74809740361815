import { useEffect, useMemo, useState } from 'react';
import { CSVData } from '../types';
import { clearEmptyRows } from '../utils';

type Props = {
  csvFile: File;
};

type UseReadCsvFile = {
  csvData: CSVData;
};

function useReadCsvFile({ csvFile }: Props): UseReadCsvFile {
  const [csvData, setCsvData] = useState<CSVData>([]);

  useEffect(() => {
    if (!csvFile) return;

    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const text = event.target?.result;
      if (typeof text === 'string') {
        const data = parseCSV(text);
        setCsvData(data);
      }
    };

    reader.readAsText(csvFile);

    return () => {
      reader.abort();
    };
  }, [csvFile]);

  const parseCSV = (text: string): CSVData => {
    return text.split('\n').map((row) => row.split(','));
  };

  const clearCsvData = useMemo(() => {
    return clearEmptyRows(csvData);
  }, [csvData]);

  return {
    csvData: clearCsvData,
  };
}

export { useReadCsvFile };
