import { useEffect, useState } from 'react';
import { UnityPayload } from '@innovamat/resource-viewer';
import { storage } from '@innovamat/radiance-utils';
import { isDesktop, isIOS } from 'react-device-detect';
import ProgressScreen from './components/progress-screen';
import { Tokens } from './interface';
import TrainingZone from './components/training-zone';
import CatchUpZone from './components/catch-up-zone';
import VacationMode from './components/vacation-mode';
import { Trans, useTranslation } from 'react-i18next';
import FeedbackScreen from './components/feedback-screen';
import { LOAD_ACTIVITY, useWebviewBridge } from '@innovamat/webview-bridge';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { MessageType } from '@innovamat/resource-viewer';
import {
  createMetricsObject,
  useCompleteMetrics,
} from './hooks/use-complete-metrics';
import {
  AnnouncementPanel,
  Modal,
  TitleHeader,
} from '@innovamat/glimmer-components';
import StudentViewModal from './components/student-view-modal';
import defaultAppletThumbnail from './assets/images/defaultAppletThumbnailGray.png';
import {
  Applet,
  RegionParameters,
  Test,
  TestData,
  WeeklyPractice,
  useAvailableTestsQuery,
  useSkipSessionActivityMutation,
  useWeeklyPracticeQuery,
} from '@innovamat/glow-api-client';
import { TestsBanner } from './components/tests-banner';
import { useWeeklyPractice } from './hooks/use-weekly-practice';
import {
  NO_LESSONS_DONE_EXCEPTION,
  useStartWeeklyPractice,
} from './hooks/use-start-weekly-practice';
import { useTrainingZone } from './hooks/use-training-zone';
import ActivitiesSlider from './components/activities-slider';
import {
  getBackground,
  getBackground3rdOr4thSE,
} from './utils/getImagesSecundaria';
import {
  User,
  normalizeImgSource,
  useAppletInfo,
} from '@innovamat/ga-features';
import SessionWrapper from './components/session-wrapper';
import { ActivityType } from './components/session-wrapper/session-wrapper';
import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';
import { useCatchUpZone } from './hooks/use-catch-up-zone';
import { NoActivitiesPopUp } from './components/no-activities-pop-up';

const WeeklyPracticeWrapper = styled.div`
  padding: 0px 48px 0px 0px;
  overflow-x: hidden;
  overflow-y: hidden;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 0px 48px;
  }
`;

type Props = {
  setEventData: (eventType: string, eventProperties?: any) => void;
  user: User;
  tokenInfo: Tokens;
  regionParameters: RegionParameters;
  isStudentViewActive: boolean;
  apolloServer: string;
};

function DigitalPractice({
  setEventData,
  user,
  tokenInfo,
  regionParameters,
  isStudentViewActive,
  apolloServer,
}: Props): JSX.Element {
  const [openApplet, setOpenApplet] = useState(false);
  const [unityPayload, setUnityPayload] = useState<UnityPayload>();
  const [currentApplet, setCurrentApplet] = useState<Applet>();
  const [backgroundImg] = useState(Math.floor(Math.random() * 7) + 1);
  const [background3rdOr4thImg] = useState(Math.floor(Math.random() * 5) + 1);
  const [showProgressScreen, setShowProgressScreen] = useState(false);
  const [currentActivityIndex, setCurrentActivityIndex] = useState<number>();
  const [willShowProgressScreen, setWillShowProgressScreen] = useState(false);
  const [showFeedbackScreen, setShowFeedbackScreen] = useState(false);
  const [trainingZoneActive, setTrainingZoneActive] = useState(false);
  const [catchUpActive, setCatchUpActive] = useState(false);
  const [waitingTrainingZoneNextApplet, setWaitingTrainingZoneNextApplet] =
    useState(false);
  const [showStudentModeViewModal, setShowStudentModeViewModal] =
    useState(false);
  const [showException, setShowException] = useState(false);
  const [openNoActivitiesPopUp, setOpenNoActivitiesPopUp] = useState(false);

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const appletInfo = useAppletInfo();

  const { isInWebview, isInIOS, sendEventToWebview } = useWebviewBridge();
  const { organizationId, locale, id: userId } = user;

  const [currentTest, setCurrentTest] = useState<TestData>();

  const skipSessionActivity = useSkipSessionActivityMutation();

  const { catchUpZone, loadingCatchUpZone, catchUpZoneHasMoreData } =
    useCatchUpZone({ locale });

  const { weeklyPractice, refetchWeeklyPractice, loadingWeeklyPractice } =
    useWeeklyPractice({ locale });

  const { startWeeklyPractice, loadingStartWeeklyPractice } =
    useStartWeeklyPractice({ locale });

  const { trainingZone, loadingTrainingZone, refetchTrainingZone } =
    useTrainingZone({ locale });

  const isLoadingWeeklyPractice =
    loadingWeeklyPractice || loadingStartWeeklyPractice;

  const weeklyPracticeActivities = weeklyPractice?.activities;

  const weeklyPracticeFinished = !!(
    weeklyPracticeActivities &&
    !isLoadingWeeklyPractice &&
    !weeklyPracticeActivities?.find(
      (applet: Applet | null) => !applet?.completed
    )
  );

  const isMobileTabletBrowser = !isDesktop && !isInWebview;

  const getNextActivityIndex = () => {
    if (!weeklyPracticeActivities) return 0;
    if (weeklyPracticeFinished) return Infinity;
    if (
      weeklyPracticeActivities?.findIndex(
        (applet: Applet | null) => !applet?.completed
      ) === 0
    ) {
      return 0;
    }
    if (
      weeklyPracticeActivities?.findIndex(
        (applet: Applet | null) => !applet?.completed
      ) === -1
    ) {
      return weeklyPracticeActivities.length - 1;
    }
    return weeklyPracticeActivities?.findIndex(
      (applet: Applet | null) => !applet?.completed
    );
  };

  const courseOrder = user.studentData?.courseOrder!;

  const nextActivityIndex = getNextActivityIndex();

  const getPractice = () => {
    refetchWeeklyPractice();
    refetchTrainingZone();
  };

  const onSetCurrentApplet = (applet?: Applet) => {
    setCurrentApplet(applet);
    if (applet) {
      setEventData('digital_practice_activity_start', {
        scene: applet?.sceneName,
        pack: applet?.pack,
      });

      if (!applet.data?.name) {
        setEventData('sys_applet_not_found', {
          scene: applet?.sceneName,
          pack: applet?.pack,
        });
      }
    }
  };

  useEffect(() => {
    if (!isLoadingWeeklyPractice && openApplet && weeklyPracticeActivities) {
      if (willShowProgressScreen) {
        setShowProgressScreen(true);
        setWillShowProgressScreen(false);
        setCurrentActivityIndex(currentApplet?.index!);
      }
      const applet = weeklyPracticeActivities.find(
        (_applet: Applet | null) => !_applet?.completed
      );
      setUnityPayload(weeklyPractice?.unityPayload);
      onSetCurrentApplet(applet!);
    }
  }, [isLoadingWeeklyPractice, weeklyPractice]);

  useEffect(() => {
    if (
      waitingTrainingZoneNextApplet &&
      !loadingTrainingZone &&
      trainingZone?.activities
    ) {
      const indexApplet = trainingZone?.activities.findIndex(
        (activity: Applet | null) =>
          activity?.sceneName === currentApplet?.sceneName &&
          activity?.pack === currentApplet?.pack
      );
      let applet;
      if (
        indexApplet === -1 ||
        indexApplet === undefined ||
        indexApplet + 1 >= trainingZone?.activities.length
      )
        applet = trainingZone?.activities[0];
      else applet = trainingZone?.activities[indexApplet + 1];
      setCurrentApplet(applet!);
      setShowFeedbackScreen(false);
      setWaitingTrainingZoneNextApplet(false);
    }
  }, [
    loadingTrainingZone,
    waitingTrainingZoneNextApplet,
    trainingZone?.activities,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setShowProgressScreen(false);
      setCurrentActivityIndex(undefined);
    }, 8500);
  }, [showProgressScreen]);

  const handleRedirectToAppStore = (): void => {
    const downloadiOSApp = t('link.downloadiOsApp');
    const downloadApp = t('link.downloadApp');
    if (isIOS) {
      window.location.href = downloadiOSApp;
    } else {
      window.location.href = downloadApp;
    }
  };

  const handleStartPractice = () => {
    if (isStudentViewActive) {
      setShowStudentModeViewModal(true);
      return;
    }
    if (isMobileTabletBrowser) {
      handleRedirectToAppStore();
      return;
    }
    startWeeklyPractice(
      {
        locale,
      },
      {
        onSuccess(response) {
          queryClient.setQueryData(useWeeklyPracticeQuery.getKey(), {
            weeklyPractice: response.startWeeklyPractice,
          });
        },
        onError(error) {
          const errors = error.response.errors;
          const errorType = errors[0].extensions.response.body['type' as any];
          if (errorType === NO_LESSONS_DONE_EXCEPTION) {
            setOpenNoActivitiesPopUp(true);
          } else {
            console.error(error);
          }
        },
      }
    );

    if (!isInWebview) {
      setOpenApplet(true);
      setEventData('digital_practice_start');
    }
  };

  const handleStartSessionApplet = (applet: Applet) => {
    if (isMobileTabletBrowser) {
      handleRedirectToAppStore();
    } else {
      setUnityPayload(weeklyPractice?.unityPayload);
      handleStartApplet(applet, weeklyPractice?.unityPayload);
    }
  };

  const handleStartTrainingApplet = (applet: Applet) => {
    if (isMobileTabletBrowser) {
      handleRedirectToAppStore();
    } else {
      setUnityPayload(trainingZone?.unityPayload);
      handleStartApplet(applet, trainingZone?.unityPayload);
      setTrainingZoneActive(true);
    }
  };

  const handleStartCatchUpApplet = (applet: Applet) => {
    if (isMobileTabletBrowser) {
      handleRedirectToAppStore();
    } else {
      setUnityPayload(catchUpZone?.unityPayload);
      handleStartApplet(applet, catchUpZone?.unityPayload);
      setCatchUpActive(true);
    }
  };

  const getQuizUnityProps = () => {
    const activitiesList = currentTest!.activities
      .filter((activity) => !activity?.completed)
      .map((activity) => activity?.id);
    return {
      unityPayload: {
        quizSessionEntity: {
          quizUuid: currentTest!.id,
          quizUserUuid: currentTest!.userTestUuid,
          isNavigable: currentTest!.navigation,
          currentActivity: 0,
          allActivitiesList: activitiesList,
          activitiesList,
        },
        userToken: '',
        userId: '',
      },
      unityActivity: currentApplet?.unityActivity,
      onUpdateTokens: handleUpdateTokens,
    };
  };

  const handleStartApplet = (
    applet: Applet,
    unityPayloadToSendFromWebview?: any
  ) => {
    if (isStudentViewActive) return;
    if (!isLoadingWeeklyPractice) {
      onSetCurrentApplet(applet);
      setOpenApplet(true);
      const defaultActivities = [
        {
          ...applet?.unityActivity,
        },
      ];

      const type = applet?.type as ActivityType;

      if (isInWebview && type !== 'WEB') {
        const defaultSessionEntity = {
          activities: type !== 'QUIZ' ? defaultActivities : [],
          bufferingSessions: [],
          name: 'Default session name',
          surname: 'Default session surname',
          schoolYear: 10,
          accomplishedContents: 0,
          totalContents: 100,
          hasReachedMaxMultiplier: false,
          currentMultiplier: 0,
        };
        if (
          unityPayloadToSendFromWebview?.sessionEntity?.activities?.length === 0
        ) {
          unityPayloadToSendFromWebview.sessionEntity.activities =
            defaultActivities;
        }
        const { refreshToken, accessToken, idToken } = tokenInfo;
        sendEventToWebview(LOAD_ACTIVITY, {
          sessionEntity: defaultSessionEntity,
          ...unityPayloadToSendFromWebview,
          sceneName: applet.sceneName,
          uuid: applet.uuid,
          packOrVariation: `${applet.pack}`,
          regionParameters,
          platformApiUrl: appletInfo.platformUrl,
          refreshToken,
          accessToken,
          idToken,
          userId: unityPayloadToSendFromWebview?.userId,
          userToken: unityPayloadToSendFromWebview?.userToken,
          language: locale,
          isSecondary: true,
        });
      } else {
        setOpenApplet(true);
      }
    }
  };

  const handleCloseApplet = () => {
    setOpenApplet(false);
    setCurrentApplet(undefined);
    setShowFeedbackScreen(false);
    setTrainingZoneActive(false);
    setCatchUpActive(false);
    setCurrentTest(undefined);
    getPractice();
    refetchAvailableTests();
  };

  const actionsWhenComplete = async () => {
    if (trainingZoneActive) {
      setShowFeedbackScreen(true);
      return;
    }
    if (catchUpActive) {
      handleCloseApplet();
      return;
    }
    if (!currentApplet) return;
    if (currentApplet.type === 'QUIZ' && currentTest) {
      if (currentTest!.activities.length === 1) {
        handleCloseApplet();
      } else {
        setCurrentTest((prevState) => {
          if (!prevState) return undefined;
          return {
            ...prevState,
            activities: prevState.activities.slice(1),
          };
        });
      }
    } else {
      if (
        currentApplet.index &&
        weeklyPracticeActivities?.length &&
        currentApplet.index + 1 > weeklyPracticeActivities.length
      ) {
        handleCloseApplet();
      } else {
        setTimeout(() => {
          getPractice();
          setWillShowProgressScreen(true);
        }, 1500);
      }
    }
  };

  const actionsWhenError = () => {
    handleCloseApplet();
  };

  const completeMetrics = useCompleteMetrics({
    onSuccess: actionsWhenComplete,
    onError: actionsWhenError,
  });

  const handleCompleteMetrics = (args: MessageType['arguments']) => {
    if ('metrics' in args) {
      const { unityActivity } = currentApplet!;
      const metrics = createMetricsObject({
        user: Number.parseInt(unityPayload!.userId),
        userToken: unityPayload?.userToken || '',
        metric: args.metrics,
        duration: args.totalTime,
        activityData: unityActivity,
        sessionType: unityPayload!.sessionEntity.sessionType,
      });
      completeMetrics({ body: metrics });
    }
  };

  const handleAppletIsCompleted = async (args?: MessageType['arguments']) => {
    if (args) {
      handleCompleteMetrics(args);
    } else {
      actionsWhenComplete();
    }
  };

  const handleStartNextApplet = (isTrainingZone?: boolean) => {
    getPractice();
    setShowException(false);
    setWaitingTrainingZoneNextApplet(!!isTrainingZone);
  };

  const handleFS = useFullScreenHandle();

  const showAppletModal = !!(currentApplet && openApplet);

  useEffect(() => {
    if (!isInIOS) {
      try {
        if (showAppletModal) handleFS.enter();
        else if (handleFS.node.current) handleFS.exit();
      } catch (e) {
        console.log(e);
      }
    }
  }, [showAppletModal]);

  const bannerInstallApp = (
    <Trans
      i18nKey="weeklyPractice.banner.warning.installApp"
      components={{
        bold: <strong />,
        underline: <u />,
        downloadLink: (
          <a
            href={isIOS ? t('link.downloadiOsApp') : t('link.downloadApp')}
            target="_blank"
            rel="noreferrer"
            style={{ color: 'black' }}
          />
        ),
      }}
    ></Trans>
  );

  const hasNoMoreTrainingActivities = trainingZone?.activities?.length === 1;

  const handleUpdateTokens = (tokens: Tokens) => {
    const { accessToken, refreshToken, idToken } = tokens;

    const newTokens = {
      access_token: accessToken,
      refresh_token: refreshToken,
      id_token: idToken,
      expires_in: 3600,
      token_type: 'Bearer',
    };
    storage.tokenInfo.set(newTokens);
  };

  const handleException = () => {
    handleSkipActivity();
    if (isInWebview) {
      handleStartNextApplet();
    } else {
      if (handleFS.node.current) handleFS.exit();
      setShowException(true);
    }
  };

  const handleSkipActivity = () => {
    skipSessionActivity.mutate({
      body: {
        userUuid: userId!,
        sessionType: weeklyPractice?.unityPayload?.sessionEntity.sessionType,
        isSkippedByFenix: true,
        codename: currentApplet?.unityActivity.codename,
      },
    });
  };

  const handleTestDataReceived = (testData: TestData): void => {
    setCurrentTest(testData);
  };

  useEffect(() => {
    handleTestActivities();
  }, [currentTest]);

  const handleTestActivities = (): void => {
    if (currentTest && currentTest.activities) {
      const activity =
        currentTest.activities.find((activity) => !activity?.completed) ||
        currentTest.activities[0];
      if (!activity) return;
      const applet: Applet = {
        ...activity,
        sceneName: activity.scene!,
        pack: activity.pack!,
        stars: 0,
        completed: false,
        index: 0,
        type: activity.type || 'QUIZ',
        uuid: activity.id,
        unityActivity: {
          ...activity,
          is_motor: false,
          scene_name: activity.scene,
          variation: 0,
        },
      };
      setCurrentApplet(applet);
      handleStartApplet(applet, getQuizUnityProps().unityPayload);
    }
  };

  const type = currentApplet?.type as ActivityType;
  let unityProps = undefined;
  let activityParameters = undefined;

  if (type === 'WEB') {
    activityParameters = {
      type: 'web',
      layout: currentApplet?.layout!,
      minDuration: currentApplet?.unityActivity.min_duration,
      maxDuration: currentApplet?.unityActivity.max_duration,
      minStatements: currentApplet?.unityActivity.min_statements,
      id: currentApplet?.uuid!,
      activityName: `${
        currentApplet?.sceneName
      }_${currentApplet?.pack?.toString()}`,
    };
  } else if (type === 'QUIZ' && currentTest) {
    unityProps = getQuizUnityProps();
  } else {
    unityProps = {
      unityPayload: unityPayload!,
      unityActivity: currentApplet?.unityActivity,
      onUpdateTokens: handleUpdateTokens,
    };
  }

  const sampleActivityArray = (length: number): WeeklyPractice => {
    const array = [];
    for (let i = 0; i < length; i++) {
      array.push({
        sceneName: `Applet - ${i + 1}`,
        data: {
          thumbnail: normalizeImgSource(defaultAppletThumbnail),
          id: '',
          name: '',
          description: '',
        },
        pack: 1,
        stars: i,
        completed: true,
        index: i,
        type: 'sample',
        uuid: '',
      });
    }
    return {
      activities: array,
      active: false,
      unityPayload: {
        sessionEntity: [],
        userToken: '',
        userId: '',
      },
    };
  };

  const {
    data: { availableTests } = { availableTests: [] },
    refetch: refetchAvailableTests,
  } = useAvailableTestsQuery(
    { userId: userId! },
    {
      enabled: !!userId,
    }
  );

  const backgroundDefault = getBackground(backgroundImg).src;

  const background3rdOr4thSE = getBackground3rdOr4thSE(
    background3rdOr4thImg
  ).src;

  const renderBackground = (courseOrder: number): string => {
    return courseOrder === 14 || courseOrder === 15
      ? background3rdOr4thSE
      : backgroundDefault;
  };

  const sliderBackground = renderBackground(courseOrder);

  return (
    <WeeklyPracticeWrapper>
      {!isDesktop && !isInWebview && (
        <BannerContainer>
          <AnnouncementPanel type="warning" text={bannerInstallApp} />
        </BannerContainer>
      )}
      <TitleHeader title={t('weeklyPractice.title')} hasSeparator={false} />
      {weeklyPractice?.isVacation ? (
        <VacationMode />
      ) : (
        <ActivitiesSlider
          weeklyPracticeActivities={weeklyPracticeActivities}
          handleStartPractice={handleStartPractice}
          loading={isLoadingWeeklyPractice}
          nextActivityIndex={nextActivityIndex}
          backgroundImg={sliderBackground}
          handleStartApplet={handleStartSessionApplet}
          weeklyPracticeFinished={weeklyPracticeFinished}
          courseOrder={courseOrder}
          firstName={user.name}
        />
      )}
      {!!availableTests?.length && (
        <TestsBanner
          availableTests={availableTests as Test[]}
          onTestDataReceived={handleTestDataReceived}
          setEventData={setEventData}
        />
      )}
      <TrainingZone
        session={isStudentViewActive ? sampleActivityArray(3) : trainingZone}
        handleStartApplet={handleStartTrainingApplet}
        isLoading={loadingTrainingZone}
        isStudentViewActive={isStudentViewActive}
      />
      <CatchUpZone
        session={isStudentViewActive ? sampleActivityArray(8) : catchUpZone}
        handleStartApplet={handleStartCatchUpApplet}
        loading={loadingCatchUpZone}
        loadMore={Boolean(catchUpZoneHasMoreData)}
        isStudentViewActive={isStudentViewActive}
      />
      <Modal
        isOpen={showAppletModal}
        onClose={handleCloseApplet}
        modalWidth="100vw"
        modalHeight="100vh"
      >
        <FullScreen handle={handleFS}>
          <AppletContainer id="appWrapper">
            {!showProgressScreen && !showFeedbackScreen && !showException && (
              <SessionWrapper
                type={type!}
                unityProps={unityProps}
                onCloseApplet={handleCloseApplet}
                onAppletIsCompleted={handleAppletIsCompleted}
                sceneName={currentApplet?.sceneName || ''}
                packOrVariation={currentApplet?.pack?.toString() || ''}
                userParameters={{
                  language: locale,
                  school: organizationId!,
                  uid: userId!,
                }}
                activityParameters={activityParameters}
                appletInfo={appletInfo}
                onException={handleException}
                setEventData={setEventData}
                apolloServer={apolloServer}
                slackApiTokens="" // TODO
                slackApiUrl="" // TODO
              />
            )}
            {(showProgressScreen || showException) && (
              <AbsolutContainer>
                <ProgressScreen
                  activities={weeklyPracticeActivities}
                  backgroundImg={sliderBackground}
                  nextActivityIndex={nextActivityIndex}
                  currentActivityIndex={currentActivityIndex}
                  showException={showException}
                  onStartNextApplet={handleStartNextApplet}
                />
              </AbsolutContainer>
            )}
            {showFeedbackScreen && (
              <AbsolutContainer>
                <FeedbackScreen
                  backgroundImg={sliderBackground}
                  thumbnail={currentApplet?.data?.thumbnail || ''}
                  onClose={handleCloseApplet}
                  hasNoMoreApplets={hasNoMoreTrainingActivities}
                  onStartNextApplet={handleStartNextApplet}
                  userId={userId}
                  activityId={currentApplet?.uuid || ''}
                  isLoading={loadingTrainingZone}
                />
              </AbsolutContainer>
            )}
          </AppletContainer>
        </FullScreen>
      </Modal>
      <StudentViewModal
        isOpen={showStudentModeViewModal}
        onClose={() => setShowStudentModeViewModal(false)}
      />
      <NoActivitiesPopUp
        isOpen={openNoActivitiesPopUp}
        onClose={() => setOpenNoActivitiesPopUp(false)}
      />
    </WeeklyPracticeWrapper>
  );
}

const BannerContainer = styled.div`
  width: fit-content;
`;

const AbsolutContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`;

const AppletContainer = styled.div`
  background-color: white;
  bottom: 0;
  height: 100vh;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
`;

export { DigitalPractice };
