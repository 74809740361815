import { GA_URL } from './variables';

function redirectWithReferral(): void {
  const pathname = window.location.pathname;
  const urlBase = encodeURIComponent(window.location.href);
  const url = pathname !== '/' ? `${GA_URL}?referral=${urlBase}` : `${GA_URL}`;

  window.location.href = url;
}

export { redirectWithReferral };
