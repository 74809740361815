import { useEffect } from 'react';

import {
  APP_PATHS,
  ClassroomStudents,
  ClassroomTeachers,
  CloseButtonFullScreen,
  ERROR_TYPE,
  ErrorPage,
  useCurrentNavigationValues,
  useNavigation,
  useOrganization,
  useReportsFullScreen,
} from '@innovamat/ga-features';
import { useNavigate, useParams } from 'react-router-domv6';
import { useSitemapNavigation } from '../../hooks/use-sitemap-navigation';
import { Header } from '../layout/header';
import { MainLayout } from '../layout/main-layout';
import { SessionDoneProviderWrapper } from './session-done-provider';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Breadcrumb, IconButton } from '@innovamat/glimmer-components';
import { stages } from '@innovamat/radiance-utils';
import { PageWithTableWrapper } from '../layout/page-with-table';
import { ImportsPage } from '../pages/imports-page';
import { ImportCSVWrapper } from './import-csv-wrapper';
import { ReportsNavigationWrapper } from './reports-navigation-wrapper';

type ClassroomStaticMenus = 'teachers' | 'students' | 'reports' | 'imports';

type Params = {
  classroomId: string;
  menu?: ClassroomStaticMenus;
  submenu?: string;
  organizationId: string;
};

const StyledMainLayout = styled(MainLayout)<{ isOverflowVisible: boolean }>`
  ${({ isOverflowVisible }) => {
    if (isOverflowVisible) {
      return css`
        .page-frame {
          overflow-y: visible;
          overflow-x: visible;
        }
      `;
    }
    return ``;
  }};
`;

export function AdminClassroomNavigationWrapper(): JSX.Element {
  const navigate = useNavigate();
  const { organizationId, menu, submenu } = useParams<Params>();
  const { currentClassroom } = useCurrentNavigationValues();
  const { organization } = useOrganization();
  const { sitemapTree, isPending, isError } = useSitemapNavigation();
  const { goBackOrHome, fromState } = useNavigation();

  const comesFromAdminDashboard =
    fromState?.includes('admin-dashboard') && submenu === 'general-progress';

  const sitemapTreeError = (!isPending && sitemapTree.length === 0) || isError;

  const { isFullScreen, closeFullScreen, openFullScreen } =
    useReportsFullScreen();

  const submenusRequiringFullScreen = new Set([
    'last-year',
    'general-progress',
    'weekly-results',
  ]);
  const shouldHideLateralMenuWithTransition = menu === 'reports';

  const getComponentRoute = (): JSX.Element => {
    switch (menu) {
      case 'teachers':
        return <ClassroomTeachers />;
      case 'students':
        return (
          <ImportCSVWrapper>
            <ClassroomStudents />
          </ImportCSVWrapper>
        );
      case 'reports':
        return <ReportsNavigationWrapper />;
      case 'imports':
        return <ImportsPage />;
      default:
        return <></>;
    }
  };

  const handleGoBack = (): void => {
    navigate(
      `${APP_PATHS.ORGANIZATION_URL}/${organizationId}${
        APP_PATHS.CLASSROOMS_URL
      }?stage=${stages.getStageFromOrder(currentClassroom?.courseOrder!)}`
    );
  };

  if (sitemapTreeError) {
    return <ErrorPage errorType={ERROR_TYPE.NOT_FOUND} />;
  }

  const handleCloseFullScreen = (): void => {
    if (submenu === 'last-year' || comesFromAdminDashboard) {
      goBackOrHome();
    }
    closeFullScreen();
  };

  useEffect(() => {
    if (
      (submenu && submenusRequiringFullScreen.has(submenu)) ||
      comesFromAdminDashboard
    ) {
      openFullScreen();
    }
  }, [submenu]);

  return (
    <>
      <Header
        leftActions={
          isFullScreen ? (
            <CloseButtonFullScreen
              onCloseFullScreen={() => handleCloseFullScreen()}
            />
          ) : (
            <>
              <IconButton icon="BackIcon" onClick={handleGoBack} />
              <Breadcrumb
                goFrom={handleGoBack}
                breadcrumbs={[organization?.name, currentClassroom?.name]}
              />
            </>
          )
        }
        hasBackButton
        sitemapTree={sitemapTree}
      />

      <StyledMainLayout
        sitemapTree={sitemapTree}
        isOverflowVisible={menu === 'reports'}
        hideLateralMenu={
          sitemapTreeError || submenu === 'upload' || isFullScreen
        }
        hideLateralMenuWithTransition={shouldHideLateralMenuWithTransition}
      >
        <SessionDoneProviderWrapper>
          <PageWithTableWrapper>{getComponentRoute()}</PageWithTableWrapper>
        </SessionDoneProviderWrapper>
      </StyledMainLayout>
    </>
  );
}
