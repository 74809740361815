import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';

import {
  Password,
  TableComponent,
  Typography,
  useColumnsCustomRenderConfig,
  type CustomColumnRenderConfig,
} from '@innovamat/glimmer-components';

import { formatDate } from '../../utils/date';

import type { Test, TestsAndResults } from '../../tests-and-results';

import { EllipsisCell } from '../../../../components/common-table-components/ellipsis-cell';
import { TestReportCell } from './components/test-report-cell/test-report-cell';
import { TestStatusCell } from './components/test-status-cell/test-status-cell';
import { useTestsAndResultsColumnsConfiguration } from './hooks/use-tests-and-results-columns-configuration';

export const Container = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 1rem;
`;

type Props = {
  data: TestsAndResults;
};

const customColumnsRenderConfig = (
  t: TFunction
): CustomColumnRenderConfig<Test> => ({
  roundName: (_, row) => (
    <Typography.Body2>
      <EllipsisCell
        key={`name_${row.classroomTestRoundId}`}
        text={t(`${row.roundName}`, '-')}
      />
    </Typography.Body2>
  ),
  status: (_, row) => (
    <TestStatusCell
      key={`status_${row.classroomTestRoundId}`}
      status={row.status}
    />
  ),
  password: (_, row) => (
    <Password
      key={`password_${row.classroomTestRoundId}`}
      value={row.password}
    />
  ),
  startDate: (_, row) => formatDate(row.startDate),
  endDate: (_, row) => formatDate(row.endDate),
  report: (_, { classroomTestRoundId, roundName, report }) => (
    <TestReportCell
      key={`report_${classroomTestRoundId}`}
      testRoundId={classroomTestRoundId}
      testRoundName={roundName}
      report={report}
    />
  ),
});

const TestsAndResultsTable = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation();

  const columnsWithoutRender = useTestsAndResultsColumnsConfiguration();
  const columns = useColumnsCustomRenderConfig<Test>(
    customColumnsRenderConfig(t),
    columnsWithoutRender
  );

  return (
    <Container>
      <TableComponent
        columns={columns}
        id="tests-and-results-table"
        key="tests-and-results-table"
        rows={data}
      />
    </Container>
  );
};

export { TestsAndResultsTable };
