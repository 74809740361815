import {
  CheckStudentDocument,
  CheckStudentQuery,
  useGlowApiClient,
} from '@innovamat/glow-api-client';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { CSVData, StudentValidation } from '../types';
import { ERRORS } from '../types';

import { isCsvValid } from '../utils';

import {
  EMAIL_FIELD,
  HEADER_INDEX,
  LAST_NAME_FIELD,
  NAME_FIELD,
  SIS_ID_FIELD,
} from '../utils/constants';

type Props = {
  csvData: CSVData;
  shouldValidate: boolean;
};

type UseCheckStudentRow = {
  students: (StudentValidation | null)[];
  onClear: () => void;
  csvHasErrors: boolean;
  setStudents: Dispatch<SetStateAction<(StudentValidation | null)[]>>;
};

function useCheckStudentRow({
  csvData,
  shouldValidate,
}: Props): UseCheckStudentRow {
  const queryClient = useQueryClient();
  const [currentIndex, setCurrentIndex] = useState(0);
  const { graphqlClient } = useGlowApiClient();
  const [students, setStudents] = useState<(StudentValidation | null)[]>([]);

  const findColumnIndex = (fieldName: string): number =>
    csvData?.[HEADER_INDEX]?.findIndex((column) => column === fieldName);

  const EMAIL_COLUMN = findColumnIndex(EMAIL_FIELD);
  const LAST_NAME_COLUMN = findColumnIndex(LAST_NAME_FIELD);
  const NAME_COLUMN = findColumnIndex(NAME_FIELD);
  const SIS_ID_COLUMN = findColumnIndex(SIS_ID_FIELD);

  const studentsRows = csvData.slice(1);

  const getSisId = useCallback(
    (index: number): string | undefined => {
      if (!studentsRows[index]) return undefined;
      return studentsRows[index][SIS_ID_COLUMN!];
    },
    [SIS_ID_COLUMN, studentsRows]
  );

  const getEmail = useCallback(
    (index: number): string | undefined => {
      if (!studentsRows[index]) return undefined;
      return studentsRows[index][EMAIL_COLUMN!];
    },
    [EMAIL_COLUMN, studentsRows]
  );

  const csvHasErrors = useMemo(() => !isCsvValid(csvData), [csvData]);

  const enableCheckStudent = Boolean(
    shouldValidate &&
      !csvHasErrors &&
      getSisId(currentIndex) &&
      studentsRows.length &&
      currentIndex < studentsRows.length
  );

  const { data, isSuccess, isError } = useQuery<CheckStudentQuery>({
    queryKey: [
      'CheckStudent',
      { sisId: getSisId(currentIndex), email: getEmail(currentIndex) },
    ],
    queryFn: () =>
      graphqlClient.request(CheckStudentDocument, {
        sisId: getSisId(currentIndex),
        email: getEmail(currentIndex),
      }),
    enabled: enableCheckStudent,
    staleTime: 0,
  });

  const onClear = (): void => {
    setStudents([]);
    setCurrentIndex(0);
    queryClient.removeQueries({ queryKey: ['CheckStudent'] });
  };

  useEffect(() => {
    if (!shouldValidate) {
      return;
    }

    if (isError && currentIndex < studentsRows.length) {
      const SIS_ID = getSisId(currentIndex);

      if (!SIS_ID) {
        return;
      }

      const student = studentsRows.find(
        (row) => row[SIS_ID_COLUMN!] === SIS_ID
      );

      const rowNumber = studentsRows.findIndex((row) => row === student) + 1;
      const studentName = student?.[NAME_COLUMN];
      const studentLastName = student?.[LAST_NAME_COLUMN];
      setStudents((prevStudents) => {
        const newStudent: StudentValidation = {
          email: getEmail(currentIndex) || null,
          rowNumber,
          errorType: ERRORS.NOT_VALIDATED,
          sisId: SIS_ID,
          studentName: `${studentName} ${studentLastName}`,
          overwrites: null,
        };

        return [...prevStudents, newStudent];
      });
      setCurrentIndex(currentIndex + 1);
    }

    if (isSuccess && data && currentIndex < studentsRows.length) {
      setStudents((prevStudents) => {
        const { checkStudent } = data;

        if (!checkStudent || checkStudent.length === 0)
          return [...prevStudents, null];

        checkStudent.forEach((studentValidation) => {
          const SIS_ID = studentValidation?.data.sis_id;
          const EMAIL = studentValidation?.data.email;

          const student = studentsRows.find(
            (row) =>
              row[SIS_ID_COLUMN!] === SIS_ID || row[EMAIL_COLUMN!] === EMAIL
          );

          const studentName = student?.[NAME_COLUMN];
          const studentLastName = student?.[LAST_NAME_COLUMN];
          const rowNumber =
            studentsRows.findIndex((row) => row === student) + 1;

          if (SIS_ID) {
            const newStudentSisId: StudentValidation = {
              email: getEmail(currentIndex) || null,
              rowNumber,
              errorType: ERRORS.SIS_ID_EXISTS,
              sisId: getSisId(currentIndex)!,
              studentName: `${studentName} ${studentLastName}`,
              overwrites: {
                id: studentValidation.data.id,
                classroom: studentValidation.data.classroom,
                courseOrder: studentValidation.data.course_order,
                firstName: studentValidation.data.first_name,
                lastName: studentValidation.data.last_name,
                sisId: SIS_ID,
              },
            };
            prevStudents.push(newStudentSisId);
          }

          if (EMAIL) {
            const newStudentEmail: StudentValidation = {
              email: getEmail(currentIndex) || null,
              rowNumber,
              errorType: ERRORS.EMAIL_EXISTS,
              sisId: getSisId(currentIndex)!,
              studentName: `${studentName} ${studentLastName}`,
              overwrites: {
                id: studentValidation.data.id,
                classroom: studentValidation.data.classroom,
                courseOrder: studentValidation.data.course_order,
                email: studentValidation.data.email,
                firstName: studentValidation.data.first_name,
                lastName: studentValidation.data.last_name,
              },
            };
            prevStudents.push(newStudentEmail);
          }
        });

        return [...prevStudents];
      });
      setCurrentIndex(currentIndex + 1);
    }
  }, [
    isSuccess,
    data,
    currentIndex,
    studentsRows.length,
    studentsRows,
    NAME_COLUMN,
    LAST_NAME_COLUMN,
    SIS_ID_COLUMN,
    EMAIL_COLUMN,
    isError,
    getSisId,
    getEmail,
    shouldValidate,
  ]);

  return {
    students,
    onClear,
    csvHasErrors,
    setStudents,
  };
}

export { useCheckStudentRow };
