import { useSolverApiUrlQuery } from '@innovamat/glow-api-client';
import { useUser } from '../../user-management';
import { useEnvVariables } from './../../env-variables-manager/env-variables-provider';

export function useSolverUrl(): {
  solverUrl: string;
} {
  const { isLoggedIn } = useUser();
  const { API_SOLVER_URL } = useEnvVariables();
  const query = useSolverApiUrlQuery(
    {},
    { staleTime: Infinity, enabled: isLoggedIn }
  );

  return {
    solverUrl: query.data?.solverApiUrl || API_SOLVER_URL,
  };
}
