import styled from '@emotion/styled';
import { useEventLogging } from '@innovamat/event-logging';
import { useFlag } from '@innovamat/flags';
import {
  APP_PATHS,
  ContentListType,
  ContentPage,
  ContentResource,
  Page,
  useCurrentNavigationValues,
  useMarkSessionDone,
  useNavigation,
  useUser,
} from '@innovamat/ga-features';
import {
  AnnouncementPanel,
  snack,
  Typography,
} from '@innovamat/glimmer-components';
import { roles } from '@innovamat/radiance-utils';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-domv6';

type ContentPageRouterProps = {
  title: string;
  pageId?: string;
  isYearUpdate?: boolean;
};

const StyledAnnouncementPanel = styled(AnnouncementPanel)`
  margin-right: 24px;
  width: auto;
`;

function YearUpdateAnnouncementPanel(): JSX.Element {
  const { navigateInSameNavType } = useNavigation();
  return (
    <StyledAnnouncementPanel
      text={
        <Trans
          i18nKey="classroom.updatePeriod.announcement"
          components={{
            a: (
              <Typography.Link2
                onClick={() =>
                  navigateInSameNavType(APP_PATHS.CLASSROOM_STUDENTS_URL)
                }
              />
            ),
          }}
        />
      }
      type="imported"
      canClose={false}
    />
  );
}

function LeadAnnouncementPanel(): JSX.Element {
  const { t } = useTranslation();
  // TODO: change to new Banner component when available
  return (
    <StyledAnnouncementPanel
      text={
        <>
          <Typography.Subtitle2>
            {t('lead.disclaimer.title')}
          </Typography.Subtitle2>
          <Typography.Body2>
            {t('lead.disclaimer.description')}
          </Typography.Body2>
        </>
      }
    />
  );
}

export function ContentPageRouter({
  title,
  pageId,
  isYearUpdate,
}: ContentPageRouterProps): JSX.Element | null {
  const { user, isRole } = useUser();
  const { onMarkSession, onToggleSolutions } = useMarkSessionDone();
  const { setEventData } = useEventLogging();
  const { goToResource, navigateInSameNavType } = useNavigation();
  const { classroomId } = useParams();
  const { pathname: fromUrl } = useLocation();
  const { getTypeOfPagePermission, currentPage } = useCurrentNavigationValues();

  const handleResourceClick = (
    resource: ContentResource,
    list: ContentListType,
    page: Page
  ): void => {
    setEventData('resource_accessed_from_list', {
      page_id: page.id,
      page_name: page.name,
      page_type: currentPage ? getTypeOfPagePermission(currentPage) : undefined,
      list_id: list.id,
      list_name: list.name,
      list_type: list.list_type,
      list_order: page.content_lists.indexOf(list) + 1,
      lists_total: page.content_lists.length,
      from_url: fromUrl,
      resource_id: resource.id,
      resource_name: resource.name,
      resource_type: resource.type,
      resource_order_in_list: list.resources.indexOf(resource) + 1,
      resources_total_in_list: list.resources.length,
      is_list_preview: Boolean(list.page_id),
    });

    goToResource({
      type: resource.type,
      resourceId: resource.id,
      listId: list.id,
      pageId: page.id,
      isDigitalGuide: resource.has_digital_guide,
    });
  };

  const handleOnShowMoreClick = (pageId: string, listId: string): void => {
    const route = `/page/${pageId}`;

    setEventData('change_page', {
      page_id: pageId,
      page_name: title,
      from_list_preview_id: listId,
    });

    navigateInSameNavType(route);
  };

  const { value: isPaginationEnabled } = useFlag(
    'showResourcesPagination',
    false,
    {
      identifier: '',
      custom: {
        schoolId: user?.organization?.id || '',
      },
    }
  );

  return (
    <ContentPage
      isPaginationEnabled={isPaginationEnabled}
      title={title}
      pageId={pageId}
      onResourceClick={handleResourceClick}
      onShowMoreClick={handleOnShowMoreClick}
      onError={() => snack.error('Error loading page')}
      setEventData={setEventData}
      academicYearId={user?.organization?.academicYearId!}
      classroomId={classroomId}
      onMarkSession={classroomId ? onMarkSession : undefined}
      onToggleSolutions={classroomId ? onToggleSolutions : undefined}
      isStudentView={roles.hasStudentRole(user?.roles!)}
      yearUpdateAnnouncementPanel={
        isYearUpdate ? <YearUpdateAnnouncementPanel /> : undefined
      }
      leadAnnouncementPanel={
        isRole.Lead ? <LeadAnnouncementPanel /> : undefined
      }
    />
  );
}
