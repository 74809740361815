'use client';

import Script from 'next/script';

export const PreprTrackingScript = (): JSX.Element => {
  const preprScriptContent = `
    ! function (e, t, p, r, n, a, s) {
    e[r] || ((n = e[r] = function () {
    n.process ? n.process.apply(n, arguments) : n.queue.push(arguments)
    }).queue = [], n.t = +new Date, (a = t.createElement(p)).async = 1, a.src = "https://cdn.tracking.prepr.io/js/prepr_v2.min.js?t=" + 864e5 * Math.ceil(new Date / 864e5), (s = t.getElementsByTagName(p)[0]).parentNode.insertBefore(a, s))
    }(window, document, "script", "prepr"), prepr("init", "ac_65cb24d6d09895b8e535eefa89ae090a368178f759c0f350ec56befe278fc8aa"), prepr("event", "pageload");
`;

  return (
    <>
      <Script
        id="prepr-script"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: preprScriptContent,
        }}
      />
      <meta id="prepr:customer-id" name="prepr:customer-id" content="" />
    </>
  );
};
