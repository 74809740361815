import { Row } from '@innovamat/glimmer-components';
import {
  getDisconnectedStudents,
  getPrimaryObservationNotes,
  getPrimaryStudentsWithDifficulties,
  getSecondaryObservationsNotes,
  getSecondaryStudentsWithDifficulties,
} from '../utils/preprocess';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import {
  ObservationsNotes,
  StudentsWithDifficulties,
} from '../views/weekly-results/weekly-results.types';
import { useMemo } from 'react';

type Props = {
  isSecundaria: boolean;
  studentsRows: Row[];
  averageRow: Row[];
  t: TFunction<'translation', undefined, 'translation'>;
  handleSendEvent: (event: string, parameters?: {}) => void;
  reportDate: null | {
    from: string;
    to: string;
  };
  classroomId: string;
  loadingTable: boolean;
  isCurrentWeek: boolean;
  tableHasData: boolean;
  handleNavigate: (
    url: string,
    queryParams?: string,
    newWindow?: boolean
  ) => void;
  locationInfo: {
    search: string;
    pathname: string;
  };
};

type SummaryDrawerProps = {
  disconnectedStudents: string[];
  observationsNotes: ObservationsNotes[];
  studentsWithDifficulties: StudentsWithDifficulties[];
  handleCloseSummaryDrawer: () => void;
  handleOpenSummaryDrawer: () => void;
  showDrawer: boolean;
};

const useSummaryDrawer = ({
  isSecundaria,
  studentsRows,
  averageRow,
  t,
  handleSendEvent,
  reportDate,
  classroomId,
  loadingTable,
  isCurrentWeek,
  tableHasData,
  handleNavigate,
  locationInfo,
}: Props): SummaryDrawerProps => {
  const [showDrawer, setShowDrawer] = useState(false);
  const { search, pathname } = locationInfo;

  const disconnectedStudents = useMemo(
    () => getDisconnectedStudents(studentsRows),
    [studentsRows]
  );
  const observationsNotes = useMemo(() => {
    return isSecundaria
      ? getSecondaryObservationsNotes(studentsRows, averageRow, t)
      : getPrimaryObservationNotes(studentsRows, t);
  }, [isSecundaria, studentsRows, averageRow, t]);

  const studentsWithDifficulties = useMemo(() => {
    return isSecundaria
      ? getSecondaryStudentsWithDifficulties(studentsRows, averageRow, t)
      : getPrimaryStudentsWithDifficulties(studentsRows, averageRow, t);
  }, [isSecundaria, studentsRows, averageRow, t]);

  const localStorageNotesWeek = localStorage.getItem(
    `notesWeek-${classroomId}`
  );

  const isNewReport = !!(
    reportDate?.from && !localStorageNotesWeek?.includes(reportDate.from)
  );

  const handleOpenSummaryDrawer = (): void => {
    if (loadingTable) return setShowDrawer(false);
    if (!isCurrentWeek && tableHasData) {
      setShowDrawer(!showDrawer);
      handleSendEvent('open_weekly_notes');
      if (isNewReport)
        localStorage.setItem(
          `notesWeek-${classroomId}`,
          `${localStorageNotesWeek || ''}${reportDate?.from}`
        );
    }
  };

  const handleCloseSummaryDrawer = (): void => {
    setShowDrawer(false);
    handleSendEvent('close_weekly_notes');
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const summary = searchParams.get('summary');
    if (summary) {
      const setQueryParam = (key: string, value: string | null): void => {
        if (value !== null) {
          searchParams.set(key, value);
        } else {
          searchParams.delete(key);
        }
        const newSearchParams = searchParams.toString();
        const currentSearchParams = search.substring(1);

        if (newSearchParams !== currentSearchParams) {
          handleNavigate('/reports/weekly-results', `?${newSearchParams}`);
        }
      };
      if (searchParams.get('summary') === 'true' && !showDrawer) {
        setQueryParam('summary', 'true');
      } else {
        setQueryParam('summary', null);
      }
    }
  }, [
    isCurrentWeek,
    pathname,
    search,
    setShowDrawer,
    showDrawer,
    tableHasData,
    handleNavigate,
  ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (
      !isCurrentWeek &&
      tableHasData &&
      searchParams.get('summary') === 'true'
    ) {
      setShowDrawer(true);
    }
  }, [isCurrentWeek, tableHasData, search, showDrawer, setShowDrawer]);

  useEffect(() => {
    if (showDrawer) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [showDrawer]);

  return {
    showDrawer,
    disconnectedStudents,
    observationsNotes,
    studentsWithDifficulties,
    handleCloseSummaryDrawer,
    handleOpenSummaryDrawer,
  };
};

export default useSummaryDrawer;
