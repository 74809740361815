import styled from '@emotion/styled';
import { Skeleton } from '@innovamat/glimmer-components';

const NUMBER_OF_ROWS = 10;
const NUMBER_OF_COLS = 10;
const CELL_WIDTH = '32';
const CELL_HEIGHT = '32';

const SkeletonFluencyTable = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: '${CELL_WIDTH}px repeat(${NUMBER_OF_COLS}, ${CELL_WIDTH}px)';
  grid-template-rows: '${CELL_HEIGHT}px repeat(${NUMBER_OF_ROWS}, ${CELL_HEIGHT}px)';
`;

const SkeletonOperator = styled(Skeleton)`
  grid-row: 1;
  grid-column: 1;
`;

const SkeletonHeader = styled(Skeleton)`
  grid-row: 1;
  grid-column: 2 / span ${NUMBER_OF_COLS};
`;

const SkeletonColumnHeader = styled(Skeleton)`
  grid-row: 2 / span ${NUMBER_OF_ROWS};
  grid-column: 1;
`;

const SkeletonTableCell = styled(Skeleton)<{ row: number; column: number }>`
  grid-row: ${({ row }) => row + 2};
  grid-column: ${({ column }) => column + 2};
`;

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const LegendSkeleton = styled.div``;

export const FluencyDashboardSkeleton = (): JSX.Element => {
  return (
    <SkeletonContainer>
      <SkeletonFluencyTable>
        <SkeletonOperator width={CELL_WIDTH} height={CELL_HEIGHT} />
        <SkeletonHeader width="100%" height={CELL_HEIGHT} />
        <SkeletonColumnHeader width={CELL_WIDTH} height="100%" />

        {Array.from({ length: NUMBER_OF_ROWS * NUMBER_OF_COLS }).map(
          (_, index) => {
            const row = Math.floor(index / NUMBER_OF_COLS);
            const column = index % NUMBER_OF_COLS;
            return (
              <SkeletonTableCell
                key={`cell-${index}`}
                width={CELL_WIDTH}
                height={CELL_HEIGHT}
                row={row}
                column={column}
              />
            );
          }
        )}
      </SkeletonFluencyTable>

      <LegendSkeleton>
        <Skeleton
          width={`${Number(CELL_WIDTH) * 13.5}`}
          height={`${Number(CELL_HEIGHT) * 1.625}`}
        />
      </LegendSkeleton>
    </SkeletonContainer>
  );
};
