import { useQueryParams } from '@innovamat/hooks';
import { Stage } from '@innovamat/radiance-utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type ResourceParams = {
  educationalLevel: string;
  region: string;
  language: string;
};

export function useResourceParams(): ResourceParams {
  const language = useQueryParams().get('language');
  const region = useQueryParams().get('region');
  const stage = useQueryParams().get('stage');
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!language) return;
    i18n.changeLanguage(language);
  }, [language]);

  if (!language || !region) {
    throw new Error('Language and region are required');
  }

  const getStage = (): Stage => {
    if (stage === 'secondary') return Stage.SECUNDARIA;
    return (stage as Stage) || Stage.PRIMARIA;
  };

  return {
    educationalLevel: getStage(),
    region,
    language,
  };
}
