import { FlagsProvider } from '@innovamat/flags';
import {
  EnvVariablesProvider,
  GoogleClassroomSyncProvider,
  UserManagementProvider,
} from '@innovamat/ga-features';
import { ThemeProvider } from '@innovamat/glimmer-components';
import { GlowApiClientProvider } from '@innovamat/glow-api-client';
import { AuthProvider, Credentials } from '@innovamat/social-login';
import { WebviewBridgeProvider } from '@innovamat/webview-bridge';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-domv6';
import { initEnvVariables } from '../../utils/init-env-variables';
import {
  getAccessToken,
  getUserAcceptLanguage,
  updateRefreshTokenClient,
} from '../../utils/update-refresh-token';

import { EventLoggingProvider } from '@innovamat/event-logging';
import { storage } from '@innovamat/radiance-utils';
import { useRegionalConfigVariables } from '@innovamat/regional-config-variables';
import { PreprTrackingProvider } from '@innovamat/smart-guides';
import { authServiceInit } from '../../config/auth-service-init';
import { redirectWithReferral } from '../../utils/redirect-with-referral';
import {
  CLIENT_ID,
  CONFIG_CAT_CACHE_TIME,
  CONFIG_CAT_KEY,
  ENVIRONMENT,
  GOOGLE_CLIENT_ID,
  GOOGLE_SECRET,
  ITERABLE_APIKEY,
  MSAL_CLIENT_ID,
  RUDDERSTACK_APIKEY,
  RUDDERSTACK_URL,
} from '../../utils/variables';

const getCredentials = (): Credentials => ({
  apolloUrl: '',
  usersClientId: CLIENT_ID,
  apiUsersUrl: '',
  msalClientId: MSAL_CLIENT_ID,
  googleClientId: GOOGLE_CLIENT_ID,
  googleSecretId: GOOGLE_SECRET,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

type Props = {
  children: React.ReactNode;
};

export function Providers({ children }: Props): JSX.Element {
  const regionalConfigVariables = useRegionalConfigVariables();
  authServiceInit(regionalConfigVariables.apiUsers);

  return (
    <EnvVariablesProvider
      envVariables={initEnvVariables(regionalConfigVariables)}
    >
      <BrowserRouter>
        <GlowApiClientProvider
          client={queryClient}
          apiUrl={regionalConfigVariables.apolloServer}
          getAuthToken={() => getAccessToken()}
          getUserAcceptLanguage={() => getUserAcceptLanguage()}
          onRefreshToken={async () => await updateRefreshTokenClient()}
          onRequestError={(error) => {
            if (error === 'UNAUTHENTICATED') {
              storage.clear();
              redirectWithReferral();
            }
          }}
        >
          <AuthProvider credentials={getCredentials()}>
            <EventLoggingProvider
              variables={{
                RUDDERSTACK_APIKEY,
                RUDDERSTACK_URL,
                ITERABLE_APIKEY,
                ENVIRONMENT,
              }}
            >
              <UserManagementProvider>
                <WebviewBridgeProvider>
                  <ThemeProvider type="light">
                    <FlagsProvider
                      sdkKey={CONFIG_CAT_KEY}
                      cacheTime={CONFIG_CAT_CACHE_TIME}
                    >
                      <GoogleClassroomSyncProvider
                        client={queryClient}
                        organizationsApiUrl={
                          regionalConfigVariables.apiOrganization
                        }
                      >
                        <PreprTrackingProvider>
                          {children}
                        </PreprTrackingProvider>
                      </GoogleClassroomSyncProvider>
                      <ReactQueryDevtools initialIsOpen={false} />
                    </FlagsProvider>
                  </ThemeProvider>
                </WebviewBridgeProvider>
              </UserManagementProvider>
            </EventLoggingProvider>
          </AuthProvider>
        </GlowApiClientProvider>
      </BrowserRouter>
    </EnvVariablesProvider>
  );
}
