import { ContentListType, ContentResource } from '@innovamat/ga-features';
import { Applet } from '@innovamat/glow-api-client';

export function createContentListByApplets(
  applets: Applet[],
  isSessionDone: boolean
): ContentListType {
  const list: ContentListType = {
    page_id: '',
    description: '',
    id: '',
    list_type: 'default',
    name: '',
    total_resources: applets.length,
    resources: applets.map((applet, index) => {
      const resource: ContentResource = {
        order: index,
        code: applet.data?.id!,
        description: applet.data?.description || '',
        enumeration: '',
        has_solutions: false,
        id: applet.data?.id!,
        is_completable: false,
        is_done: false,
        name: applet.data?.name || '',
        solutions_enabled: false,
        status: isSessionDone ? [] : ['weekly_practice'],
        thumbnail: applet.data?.thumbnail || '',
        type: 'applet',
        video_info: {
          duration: 0,
          progress: 0,
        },
      };
      return resource;
    }),
  };

  return list;
}
