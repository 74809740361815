import styled, { css } from 'styled-components';
import {
  Icon,
  InnerHtml,
  theme,
  Tooltip,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { CardType, StudentFailed } from '@innovamat/glow-api-client';

export const CELL_VARIANT = {
  CELL_NORMAL_SIZE: 'cell-normal-size',
  CELL_SMALL_SIZE: 'cell-small-size',
} as const;

type CellVariant = (typeof CELL_VARIANT)[keyof typeof CELL_VARIANT];

type Props = {
  status: CardType;
  variant: CellVariant;
  studentsFailed?: StudentFailed[];
  allCellsLocked?: boolean;
  operation?: string;
  noStudentsConnected?: boolean;
};

const StyledCell = styled.div<{
  variant: CellVariant;
  bgColor: string;
  allCellsLocked?: boolean;
}>(({ variant, bgColor, allCellsLocked }) => {
  const cellSize = {
    [CELL_VARIANT.CELL_NORMAL_SIZE]: 32,
    [CELL_VARIANT.CELL_SMALL_SIZE]: 12,
  };

  const cellBorderRadius = {
    [CELL_VARIANT.CELL_NORMAL_SIZE]: 4,
    [CELL_VARIANT.CELL_SMALL_SIZE]: 2,
  };

  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${cellSize[variant]}px;
    height: ${cellSize[variant]}px;
    border-radius: ${cellBorderRadius[variant]}px;

    ${variant === CELL_VARIANT.CELL_NORMAL_SIZE &&
    css`
      :hover {
        filter: brightness(90%);
      }
    `}

    background-color: ${allCellsLocked &&
    variant === CELL_VARIANT.CELL_SMALL_SIZE
      ? theme.tokens.color.specific.reports['progress-bar'].empty.value
      : bgColor};
  `;
});

const StyledTooltip = styled(Tooltip)`
  text-align: center;
`;

function CellFluency({
  status,
  variant = CELL_VARIANT.CELL_NORMAL_SIZE,
  studentsFailed,
  allCellsLocked,
  noStudentsConnected,
  operation,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const theme = useGlimmerTheme();

  const baseTokenReport = theme.tokens.color.specific.reports;
  const getBackgroundColor = (status: CardType): string => {
    switch (status) {
      case CardType.Locked:
      case CardType.NotSeen:
        return baseTokenReport['progress-bar'].empty.value;
      case CardType.MostlyFailed:
        return baseTokenReport.semantic['low'].value;
      case CardType.FairlyFailed:
        return baseTokenReport.semantic['medium'].value;
      case CardType.InProgress:
        return baseTokenReport.semantic['high'].value;
      case CardType.Memorized:
        return baseTokenReport.semantic['super-high'].value;
      default:
        return baseTokenReport.semantic['no-data'].value;
    }
  };

  const backgroundColor = getBackgroundColor(status);

  const renderTooltipMessage = (operation: string | undefined): string => {
    const operationLine = operation
      ? `<strong>${operation}</strong><br />`
      : '';

    if (status === CardType.Locked)
      return `${operationLine}${t('fluency.reports.hover.lockedGroup')}`;

    const students = studentsFailed
      ?.map((student) => `${student.firstName} ${student.lastName}`)
      .join('<br />');

    return `${operationLine}${students || ''}`;
  };

  return (
    <StyledTooltip
      content={<InnerHtml text={renderTooltipMessage(operation)} />}
      disabled={!studentsFailed || allCellsLocked || noStudentsConnected}
      maxWidth={250}
    >
      <StyledCell
        variant={variant}
        bgColor={backgroundColor}
        allCellsLocked={allCellsLocked}
      >
        {status === CardType.Locked && (
          <Icon
            icon="LockedIcon"
            iconColor={theme.tokens.color.alias.cm.icon['icon-disabled'].value}
          />
        )}
      </StyledCell>
    </StyledTooltip>
  );
}

export default CellFluency;
