import styled from '@emotion/styled';
import { useUser } from '@innovamat/ga-features';
import { AppStoreBadge, GooglePlayBadge } from '@innovamat/glimmer-assets';
import { Button, Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  width: 100%;
`;

const Subtitle = styled(Typography.Body2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 8px;
`;

export function OldLandingPageParent(): JSX.Element {
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <Wrapper>
      <Typography.H2>
        {t('landingParent.title', { name: user?.name })}
      </Typography.H2>
      <Container>
        <Typography.Subtitle1>
          {t('landingParent.title.accessApp')}
        </Typography.Subtitle1>
        <Subtitle>{t('landingParent.subtitle')}</Subtitle>
        <ButtonsContainer>
          <a href={t('downloadInnovamatDesktop')}>
            <Button variant="secondary" leftIcon="ManipulativeEnvironmentIcon">
              {t('landingParent.button.innovamatDesktop')}
            </Button>
          </a>
          <a href={t('downloadInnovamatApple')}>
            <AppStoreBadge />
          </a>
          <a href={t('downloadInnovamatPlayStore')}>
            <GooglePlayBadge />
          </a>
        </ButtonsContainer>
      </Container>
    </Wrapper>
  );
}
