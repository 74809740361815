import { useRef } from 'react';
import styles from './training-zone.module.scss';
import defaultAppletThumbnail from '../../assets/images/defaultAppletThumbnailGray.png';
import {
  Icon,
  ResourceCard,
  Slider,
  Tooltip,
  theme,
} from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import {
  ContentListSkeleton,
  normalizeImgSource,
  useResponsiveSlide,
} from '@innovamat/ga-features';
import { Applet, WeeklyPractice } from '@innovamat/glow-api-client';
import styled from '@emotion/styled';

export const LoaderContainer = styled.div`
  height: 240px;
  > div {
    padding: 0px !important;
  }
`;

type Props = {
  session: WeeklyPractice | null | undefined;
  handleStartApplet: (applet: Applet) => void;
  isLoading: boolean;
  isStudentViewActive: boolean;
};

function TrainingZone({
  session,
  handleStartApplet,
  isLoading,
  isStudentViewActive,
}: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  const appletsToShow = session?.activities;
  const noMoreData = session?.noMoreData;

  const { slidesToShow, initialSlide } = useResponsiveSlide({
    resources: appletsToShow,
  });
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <LoaderContainer>
        <ContentListSkeleton />
      </LoaderContainer>
    );
  }
  if (!appletsToShow?.length && !noMoreData) {
    return <></>;
  }
  return (
    <>
      <h4 className={styles.subtitle}>
        {t('weeklyPractice.subtitle.trainingZone')}
        <RelativeContainer>
          <Tooltip
            content={
              isStudentViewActive
                ? t('weeklyPractice.toolTip.trainingZone.studentViewMode')
                : t('weeklyPractice.toolTip.trainingZone')
            }
            popperOptions={{ strategy: 'fixed' }}
            placement="right"
            maxWidth={165}
          >
            <StyledIcon
              icon="InfoIcon"
              size="M"
              hasStateLayer
              iconColor="white"
              bgColor={theme.tokens.color.alias.cm.icon['icon-info'].value}
            />
          </Tooltip>
        </RelativeContainer>
      </h4>
      <div className={styles.container} ref={ref}>
        {!!appletsToShow?.length && (
          <Slider
            gap={'1rem'}
            initialIndex={initialSlide}
            slidesToShow={slidesToShow}
          >
            {appletsToShow.map((applet) => (
              <ResourceCard
                title={applet?.data?.name || applet?.sceneName || ''}
                type="applet"
                description={applet?.data?.description || ''}
                thumbnail={
                  applet?.data?.thumbnail ||
                  normalizeImgSource(defaultAppletThumbnail)
                }
                key={`${applet?.pack}${applet?.sceneName}`}
                onClick={() => handleStartApplet(applet!)}
                t={t}
                stars={applet?.stars}
              />
            ))}
          </Slider>
        )}
        {noMoreData && (
          <TrainingEndContainer>
            {t('weeklyPractice.subtitle.trainingEnd')}
          </TrainingEndContainer>
        )}
      </div>
    </>
  );
}

export default TrainingZone;

const RelativeContainer = styled.div`
  position: relative;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const TrainingEndContainer = styled.div`
  margin: 16px 0 24px;
  color: ${({ theme }) => theme.colors.dark['02']};
`;
