import styled from '@emotion/styled';

import { groupedTipSteps } from '../../digital-guides/utils/steps';

export const GroupedStep = styled.div<{ typename: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ typename }) =>
    groupedTipSteps.includes(typename as any) ? '8px' : '24px'};
`;
