import { Tooltip, Typography } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import CellFluency from '../cell-fluency';
import { CardType, MultiplicationTable } from '@innovamat/glow-api-client';
import { CELL_VARIANT } from '../cell-fluency/cell-fluency';
import { useTranslation } from 'react-i18next';
import { FluencyStatusTooltipProp } from '../../views/fluency-dashboard';

type Props = {
  operator: string;
  tableFluencyData?: MultiplicationTable[];
  showFluencyStatusTooltip?: FluencyStatusTooltipProp;
};

const TableWrapper = styled.div``;

const TooltipContainer = styled.div`
  position: absolute;
  top: calc(50% + 27px);
  left: calc(50% + 20px);
  transform: translate(-50%, -50%);
`;

const TooltipPlaceholder = styled.div``;

const StyledTooltip = styled(Tooltip)`
  text-align: center;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const CellsContainer = styled.div`
  position: relative;
`;

const HeaderCell = styled(Typography.Subtitle1)<{ isLocked?: boolean }>`
  text-align: center;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.sizes.normal};
  color: ${({ isLocked, theme }) =>
    isLocked
      ? `${theme.tokens.color.alias.cm.text['text-disabled'].value}`
      : ''};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  gap: 8px;
`;

const LabelCell = styled(Typography.Subtitle1)<{ isLocked?: boolean }>`
  text-align: center;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ isLocked, theme }) =>
    isLocked
      ? `${theme.tokens.color.alias.cm.text['text-disabled'].value}`
      : `${theme.tokens.color.alias.cm.text['text'].value}`};
`;

function TableFluency({
  operator,
  tableFluencyData,
  showFluencyStatusTooltip,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { allGroupsLocked, noStudentsConnected } =
    showFluencyStatusTooltip as FluencyStatusTooltipProp;

  const tooltipMessage = (() => {
    if (allGroupsLocked) {
      return t('fluency.reports.allGroupsLocked');
    }
    if (noStudentsConnected) {
      return t('fluency.reports.noStudentsConnected');
    }
    return null;
  })();

  const rowCount = tableFluencyData?.[0].cards.length || 0;

  return (
    <TableWrapper>
      <HeaderRow>
        <HeaderCell isLocked={allGroupsLocked}>{operator}</HeaderCell>
        {tableFluencyData?.map((column, columnIndex) => {
          const card = tableFluencyData?.[columnIndex]?.cards?.[0];
          const columnLocked = card?.status === CardType.Locked;
          return (
            <HeaderCell key={columnIndex} isLocked={columnLocked}>
              {column.operand2}
            </HeaderCell>
          );
        })}
      </HeaderRow>
      <CellsContainer>
        <TooltipContainer>
          <StyledTooltip maxWidth={280} content={tooltipMessage} visible>
            <TooltipPlaceholder />
          </StyledTooltip>
        </TooltipContainer>
        {Array.from({ length: rowCount }).map((_, rowIndex) => {
          const operand2 = tableFluencyData?.[rowIndex]?.operand2;
          const isRowLocked = tableFluencyData?.every(
            (column) => column.cards[rowIndex]?.status === CardType.Locked
          );

          return (
            <Row key={`row-${rowIndex}`}>
              <LabelCell isLocked={isRowLocked}>{operand2}</LabelCell>
              {tableFluencyData?.map((column, columnIndex) => {
                const { status, studentsFailed } = column.cards[rowIndex];
                const operand1 = rowIndex;
                const operand2 = column.operand2;
                const operation = `${operand1} ${operator} ${operand2}`;
                return (
                  <CellFluency
                    key={`cell-${rowIndex}-${columnIndex}`}
                    status={status}
                    variant={CELL_VARIANT.CELL_NORMAL_SIZE}
                    studentsFailed={studentsFailed}
                    allCellsLocked={allGroupsLocked}
                    operation={operation}
                  />
                );
              })}
            </Row>
          );
        })}
      </CellsContainer>
    </TableWrapper>
  );
}

export default TableFluency;
