import styled from '@emotion/styled';
import { OptionType, Select, Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

export type Student = {
  firstName: string;
  lastName: string;
  uuid: string;
};

type Props = {
  students: Student[];
  onChange: (option: OptionType | null) => void;
  selected: string;
};

const DropdownWrapper = styled.div`
  width: 250px;
`;

const StudentsFluencyTableDropdownWrapper = styled.div`
  display: flex;
  width: 251px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;

function StudentsFluencyTableDropdown({
  students,
  onChange,
  selected,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const getStudentOptions = (students: Student[]): OptionType[] => [
    { value: '', label: t('fluency.reports.allClassroom') },
    ...students.map((student) => ({
      value: student.uuid,
      label: `${student.firstName} ${student.lastName}`,
    })),
  ];

  const studentOptions = getStudentOptions(students);

  return (
    <StudentsFluencyTableDropdownWrapper>
      <Typography.Body2>{t('fluency.reports.selectStudent')}</Typography.Body2>
      <DropdownWrapper>
        <Select
          menuPortalTarget={document.body}
          onChange={onChange}
          options={studentOptions}
          value={selected}
        />
      </DropdownWrapper>
    </StudentsFluencyTableDropdownWrapper>
  );
}

export default StudentsFluencyTableDropdown;
