import { useLocation, useNavigate, useParams } from 'react-router-domv6';
import { useUser } from '../../user-management';
import { NAV_TYPE } from '../utils';
import { useCurrentNavigationValues } from './use-current-navigation-values';

type GoToResourceProps = {
  type: string;
  resourceId: string;
  listId?: string;
  pageId?: string;
  challengeId?: string;
  listType?: string;
  openInNewTab?: boolean;
  isDigitalGuide?: boolean;
};

type UseNavigationValues = {
  goToHome: () => void;
  goToCourse: (courseId: string) => void;
  goToClassroom: (classroomId: string) => void;
  goToResource: (props: GoToResourceProps) => void;
  goBack: () => void;
  goBackOrHome: () => void;
  goBackOrCourseSelector: () => void;
  navigateInSameNavType: (
    route: string,
    queryParams?: string,
    navType?: string
  ) => void;
  navigateBetweenClassesOrCourses: (newId: string) => void;
  goToSchoolClassroom: (classroomId: string) => void;
  fromState: string | undefined;
};

export function useNavigation(): UseNavigationValues {
  const navigate = useNavigate();
  const { pathname, state, search } = useLocation();
  const { courseId, classroomId, organizationId, menu } = useParams();
  const { navType, getFromPage, educationalLevel } =
    useCurrentNavigationValues();
  const { user } = useUser();

  const fullPathname = `${pathname}${search}`;

  const getCourseUrl = (id: string): string =>
    navType === NAV_TYPE.Learning
      ? `/${NAV_TYPE.Course}/${id}/${NAV_TYPE.Learning}/page`
      : `/${NAV_TYPE.Course}/${id}/page`;

  const goToCourse = (courseId: string): void => {
    navigate(getCourseUrl(courseId), {
      state: { from: fullPathname },
    });
  };

  const goToClassroom = (classroomId: string): void => {
    navigate(`/${NAV_TYPE.Classroom}/${classroomId}/page`, {
      state: { from: fullPathname },
    });
  };

  const navigateBetweenClassesOrCourses = (newId: string): void => {
    const newPathname = pathname.replace(
      classroomId || (courseId as string),
      newId
    );
    navigate(newPathname);
  };

  const navigateInSameNavType = (
    route: string,
    queryParams?: string,
    navTypeRoute?: string
  ) => {
    const query = queryParams || '';

    if (navTypeRoute) {
      navigate(`${navTypeRoute}${route}/page${query}`);
      return;
    }
    if (navType === NAV_TYPE.Student) {
      navigate(`/${NAV_TYPE.Student}/${classroomId}${route}/page${query}`, {
        state: { from: fullPathname },
      });
    } else if (navType === NAV_TYPE.AdminClassroom) {
      navigate(
        `/${NAV_TYPE.Admin}/${organizationId}/classrooms/${classroomId}${route}/page${query}`,
        {
          state: { from: fullPathname },
        }
      );
    } else if (navType === NAV_TYPE.Classroom) {
      navigate(`/${NAV_TYPE.Classroom}/${classroomId}${route}/page${query}`, {
        state: { from: fullPathname },
      });
    } else if (navType === NAV_TYPE.Course || navType === NAV_TYPE.Learning) {
      navigate(`/${NAV_TYPE.Course}/${courseId}${route}/page${query}`, {
        state: { from: fullPathname },
      });
    } else if (navType === NAV_TYPE.Admin) {
      navigate(`/${NAV_TYPE.Admin}/${organizationId}${route}${query}`, {
        state: { from: fullPathname },
      });
    }
  };

  const goToSchoolClassroom = (classroomId: string): void => {
    navigate(
      `/${NAV_TYPE.Admin}/${organizationId}/classrooms/${classroomId}/page`,
      {
        state: { from: fullPathname },
      }
    );
  };

  const goToHome = (): void => {
    navigate('/');
  };

  const goToResource = ({
    type,
    resourceId,
    listId,
    pageId,
    challengeId,
    listType,
    openInNewTab,
    isDigitalGuide,
  }: GoToResourceProps): void => {
    const baseUrl = () => {
      if (
        navType === NAV_TYPE.Classroom ||
        navType === NAV_TYPE.AdminClassroom
      ) {
        return `/${NAV_TYPE.Classroom}/${classroomId}`;
      }
      if (navType === NAV_TYPE.Student) {
        return `/${NAV_TYPE.Student}/${classroomId}`;
      }
      return `/${NAV_TYPE.Course}/${courseId}`;
    };

    let resourceUrl = `${baseUrl()}/${menu}/${type}/${resourceId}`;
    const queryParams = [];

    if (user?.region) {
      queryParams.push(`region=${user.region}`);
    }

    if (user?.locale) {
      queryParams.push(`language=${user.locale}`);
    }

    if (educationalLevel) {
      queryParams.push(`stage=${educationalLevel}`);
    }

    if (pageId) {
      queryParams.push(`fromPage=${pageId}`);
    }

    if (listId) {
      queryParams.push(`fromList=${listId}`);
    }

    if (challengeId) {
      queryParams.push(`fromChallenge=${challengeId}`);
    }

    if (listType) {
      queryParams.push(`listType=${listType}`);
    }

    if (isDigitalGuide) {
      queryParams.push('isDigitalGuide');
    }

    if (queryParams.length > 0) {
      resourceUrl += `?${queryParams.join('&')}`;
    }

    if (openInNewTab) {
      window.open(resourceUrl, '_blank')?.focus();
      return;
    }
    navigate(resourceUrl, { state: { from: fullPathname } });
  };

  const goBackOrHome = (): void => {
    navigate(state?.from || '/');
  };

  const goBackOrCourseSelector = (): void => {
    navigate(state?.from || `/course-selector/${navType}`);
  };

  const goBack = (): void => {
    navigate(`/${getFromPage()}`, { replace: true });
  };

  return {
    goToHome,
    goToCourse,
    goToClassroom,
    goToResource,
    goBack,
    goBackOrHome,
    goBackOrCourseSelector,
    navigateInSameNavType,
    navigateBetweenClassesOrCourses,
    goToSchoolClassroom,
    fromState: state?.from,
  };
}
