import { useTranslation } from 'react-i18next';
import { getCatchUpColumns } from './utils/get-catch-up-columns';
import { TableComponent, Typography } from '@innovamat/glimmer-components';
import { getStudentsRowsCatchUp } from './utils/preprocess';
import { useEffect, useState } from 'react';
import {
  Classroom,
  useInterventionReportQuery,
} from '@innovamat/glow-api-client';
import { useScrollToCurrentWeek } from './hooks/use-scroll-to-current-week';
import styled from '@emotion/styled';
import ReportsLoader from '../../components/reports-loader';

type Row = {
  [key: string]: any;
};

type Props = {
  classroom: Classroom;
  setEventData: (eventType: string, eventProperties?: any) => void;
};

const FlexContainer = styled.div`
  display: flex;
`;

const InterventionCompletedTitle = styled(Typography.H4)`
  margin-top: 40px;
`;

export const TableContainer = styled.div`
  display: grid;
`;

export const CatchUpDashboard = ({
  classroom,
  setEventData,
}: Props): JSX.Element => {
  const [classroomId, setClassroomId] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const appContent = document.getElementById('app-content');
    if (appContent) {
      appContent.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    }
    return () => {
      if (appContent) {
        appContent.style.overflow = '';
        document.body.style.overflow = 'scroll';
      }
    };
  }, []);

  useEffect(() => {
    if (classroom?.id) {
      setClassroomId(classroom.id);
    }
  }, [classroom]);

  const handleSendEvent = (event: string, parameters?: {}): void => {
    setEventData(event, {
      classroom_id: classroomId,
      ...parameters,
    });
  };

  useEffect(() => {
    handleSendEvent('catchup_dashboard_access');
  }, []);

  const { data, isLoading } = useInterventionReportQuery({
    id: classroomId,
  });

  useScrollToCurrentWeek(
    `current-week-${data && data.interventionReport?.weeks.currentWeekNumber}`,
    'catch-up-dashboard-table'
  );

  const interventionReport = data?.interventionReport;

  if (isLoading) return <ReportsLoader />;
  if (!interventionReport) return <FlexContainer>No data</FlexContainer>; //TODO: Change this copy

  const interventionWeeks = interventionReport.weeks;

  const columns = getCatchUpColumns({
    t,
    interventionWeeks,
  });

  const studentsRows = getStudentsRowsCatchUp(
    interventionReport.studentsCurrentlyInIntervention
  );

  const studentsNoLongerInInterventionRows = getStudentsRowsCatchUp(
    interventionReport.studentsNoLongerInIntervention
  );

  const getStudentsWithCompletedSessions = (
    studentsNoLongerInInterventionRows: Row[]
  ): Row[] => {
    return studentsNoLongerInInterventionRows.filter((student) =>
      student?.weeks?.some(
        (week: Row) => week.completedInterventionSessions > 0
      )
    );
  };

  const studentsWithCompletedSessions = getStudentsWithCompletedSessions(
    studentsNoLongerInInterventionRows
  );

  return (
    <>
      <TableContainer>
        <TableComponent
          id={'INTRE_TABLE'}
          columns={columns}
          rows={studentsRows}
          topStickyOffset={64}
        />
      </TableContainer>
      {!!studentsNoLongerInInterventionRows.length && (
        <TableContainer>
          <InterventionCompletedTitle data-testid="INTRE_DEACTIVATED_STUDENTS">
            {t('reports.catch-up.studentsNoLongerInIntervention.title')}
          </InterventionCompletedTitle>
          <TableComponent
            id={'INTRE_DEATABLE'}
            columns={columns}
            rows={studentsWithCompletedSessions}
            topStickyOffset={64}
          />
        </TableContainer>
      )}
    </>
  );
};
