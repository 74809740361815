import {
  SmartGuideElementType,
  type Content,
  type GroupedSteps,
  type StepType,
} from '../../types/SmartGuide';

export const groupedTipSteps = [
  SmartGuideElementType.ampliation,
  SmartGuideElementType.signedTip,
  SmartGuideElementType.solution,
  SmartGuideElementType.support,
  SmartGuideElementType.tip,
  SmartGuideElementType.whatToObserve,
  SmartGuideElementType.management,
];

export const groupConsecutiveSteps = (
  steps: StepType[] | Content[] | undefined,
  groupTypes: string[][] = []
): GroupedSteps[] => {
  if (!steps) return [];

  return steps.reduce((groupedSteps: GroupedSteps[], currentStep, index) => {
    if (index === 0) {
      return [{ __typename: currentStep.__typename, steps: [currentStep] }];
    }

    const lastGroup = groupedSteps[groupedSteps.length - 1];
    const shouldGroup =
      lastGroup.steps[0].__typename === currentStep.__typename ||
      groupTypes.some(
        (group) =>
          group.includes(lastGroup.steps[0].__typename) &&
          group.includes(currentStep.__typename)
      );

    if (shouldGroup) {
      lastGroup.steps.push(currentStep);
      return groupedSteps;
    }

    return [
      ...groupedSteps,
      { __typename: currentStep.__typename, steps: [currentStep] },
    ];
  }, []);
};

export const groupConsecutiveTips = (
  steps: StepType[] | Content[] | undefined
): GroupedSteps[] => {
  if (!steps) return [];

  return groupConsecutiveSteps(steps, [groupedTipSteps]);
};
