import { useEventLogging } from '@innovamat/event-logging';
import { Option } from '@innovamat/ga-features';
import { saveFile, stripHTMLTags } from '@innovamat/radiance-utils';
import { Resource } from '@innovamat/resource-viewer';
import { detectIsInWebview } from '@innovamat/webview-bridge';

type Props = {
  resource: Resource;
};

type DownloadOption = {
  onDownloadOption: (option?: Option | undefined) => void;
};

export function useDownloadOption({ resource }: Props): DownloadOption {
  const { setEventData } = useEventLogging();

  const handleDownloadOption = (option?: Option | undefined): void => {
    if (!resource) return;
    if (!option || !('resources' in resource)) return;
    const uuids = String(option.value).split('-');
    uuids.forEach((uuid) => {
      const printableToSave = resource.printables.find((o) => uuid === o.value);
      if (printableToSave) {
        const splitOriginalFile = printableToSave?.subtitle?.split('.');
        const extension = splitOriginalFile?.slice(-1).pop();
        saveFile(
          printableToSave.href,
          stripHTMLTags(printableToSave.subtitle || ''),
          detectIsInWebview
        );
        setEventData('content_printable_download', {
          resource_name: stripHTMLTags(printableToSave.subtitle || ''),
          resource_id: printableToSave.id,
          file_id: printableToSave.value,
          resource_type: extension,
          session_id: resource.id,
          session_name: resource.title,
        });
      }
    });
  };

  return { onDownloadOption: handleDownloadOption };
}
